import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.css']
})

export class FilterDialogComponent {
  @Output() filter: EventEmitter<any> = new EventEmitter();

  constructor() {}
  
  filtrar(){
    this.filter.emit("prueba");
  }
}