import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EntityService } from 'src/app/services/entity.service';
import { UsersService } from 'src/app/services/users.service';
@Component({
  selector: 'app-petitions-form-entity',
  templateUrl: './petition-form.component.html',
  styleUrls: ['./petition-form.component.css']
})
export class PetitionFormComponent {

  registerForm: FormGroup;
  departaments: any;
  instituciones:any;
  tipos_sangre: any;
  locationToSend:any;
  lat:any;
  lng:any;
  blood_types_selected: any[] = [];
  entity:any;

  constructor(private formBuilder: FormBuilder, private userService:UsersService, private router:Router, private entity_service:EntityService) { 
    this.departaments = [];
    this.tipos_sangre = [];
    this.instituciones = [];
    this.locationToSend = "";
    this.entity = undefined;
  }

  ngOnInit(){
    this.registerForm = this.formBuilder.group({
      titulo: ['', Validators.required],
      descripcion: ['', Validators.required],
      tipo_sangre: [''],
      petition_type: ["2", Validators.required],
      date_start: ["", Validators.required],
      date_end: ["", Validators.required],
    });

    this.userService.get_departaments().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        this.departaments = response.data;
      },
      error: (err:any)=>{
        console.error("Error getting departaments, line 52: ",err);
      }
    });

    this.userService.get_blood_types().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        this.tipos_sangre = response.data;
      },
      error: (err:any)=>{
        console.error("Error getting blood types, line 62: ",err);
      }
    });

    this.userService.get_places().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        this.instituciones = response.data;
      },
      error: (err:any)=>{
        console.error("Error getting departaments, line 72: ",err);
        this.userService.openDialog("Error","Error al obtener las instituciones, intente mas tarde","../../../assets/images/error.png")
      }
    });

    this.entity_service.get_entity_information().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ", response);
        this.entity = response.data[0];
        this.lat = response.data[0].lat;
        this.lng = response.data[0].lng;
        if(this.lat && this. lat){
          this.locationToSend = this.lat+','+this.lng;
        }
      },
      error: (err:any) =>{
        if(err.status == 403 || err.status == 401){
          this.userService.redirect_to_login();
        }
        console.error("Error getting user information, line 68: ",err);
      }
    });
  }

  onSubmit() {

    
    if (this.blood_types_selected.length < 1){
      this.userService.openDialog("Error","Agregue al menos un tipo de sangre","../../../assets/images/error.png");
      return;
    }

    if (!this.entity){
      this.userService.openDialog("Error","Error al cargar sus datos de institución, por favor intente más tarde","../../../assets/images/error.png");
      return;
    }

    if(this.registerForm.controls['petition_type'].value == "1"){
      this.registerForm.controls['date_end'].patchValue("na");
      this.registerForm.controls['date_start'].patchValue("na");
    }

    if(this.registerForm.controls['petition_type'].value == "2"){
      if(this.registerForm.controls['date_end'].value == "na" || this.registerForm.controls['date_start'].value == "na"){
        this.userService.openDialog("Error","Ingrese fecha de inicio y finalización de la jornada","../../../assets/images/error.png");
        return;
      }
 
    }

    if (this.registerForm.valid) {
      this.blood_types_selected.sort(this.order_by_id);
      let blood_array = this.blood_types_selected.map(bt=>{ return bt.id});
      console.log(blood_array)
      
      let data = {
        title: this.registerForm.controls['titulo'].value,
        phone: this.entity.phone,
        description: this.registerForm.controls['descripcion'].value,
        blood_type: blood_array,
        depto: this.entity.depto,
        entity: this.entity.name,
        lat: this.lat,
        lng: this.lng,
        type: this.registerForm.controls['petition_type'].value,
        date_start: this.registerForm.controls['date_start'].value,
        date_end: this.registerForm.controls['date_end'].value,
        user_type: 2
      }

      console.log(data);
      
      this.userService.new_request_entity(data).subscribe({
        next: (response:any)=>{
          console.log(response)
          if(response.status){ 
            this.userService.openDialog("¡Creada!","Su petición ha sido creada exitosamente","../../../assets/images/corazon_rojo.png");
          }
        },
        error: (err:any)=>{
          console.error("Error on create new user, line 68: ",err);
          this.userService.openDialog("Error","Error al crear su petición, intente mas tarde","../../../assets/images/error.png");
        }
      });
      console.log("Data a enviar: ",data);
    } 
    else {
      console.log('Formulario inválido',this.registerForm);
      this.userService.openDialog("Error","Llene correctamente el formulario","../../../assets/images/error.png");
    }
  }

  getLocation(location: string) {
    this.lat = location.split(',')[0];
    this.lng = location.split(',')[1];
    console.log("RECIBIDO: ",this.lat,this.lng);
  }
  SetInfo(){
    let entity = this.instituciones.find((i:any) => i.name == this.registerForm.controls['entity'].value);
    console.log("Entity",entity);
    if(entity){
      this.locationToSend = entity.lat+','+entity.lng;
      this.registerForm.controls['departament'].setValue(entity.departament); 
    }
    else{
      this.locationToSend = -1;
      this.registerForm.controls['departament'].setValue(null);
    }
  }

  remove(bt: any): void {
    const index = this.blood_types_selected.indexOf(bt);

    if (index >= 0) {
      this.blood_types_selected.splice(index, 1);
    }
  }

  push_blood_type(){
    if(this.registerForm.controls['tipo_sangre'].value == -1){
      this.blood_types_selected = this.tipos_sangre.map((t:any) => {return t;});
    }
    else{
      const bt = this.blood_types_selected.find((element:any) => element.id == this.registerForm.controls['tipo_sangre'].value.id);
    if(bt){
      return;
    }
    let blood_type = this.tipos_sangre.find((element:any) => element.id == this.registerForm.controls['tipo_sangre'].value.id);
    if(blood_type.name){
      this.blood_types_selected.push(blood_type);
    }
    }
    
  }

  order_by_id(a:any, b:any) {
    return a.id - b.id;
  }
}
