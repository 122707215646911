<!DOCTYPE html>
<html lang="es">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Error 404 - No encontrado</title>
</head>
<body>
    <h1>Error 404 - No encontrado</h1>
    <p>La página que estás buscando no se encuentra disponible.</p>
</body>
</html>