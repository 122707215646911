import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityService } from 'src/app/services/entity.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent {
  profile_form: FormGroup;
  departaments: any;
  locationToSend:any;
  lat:any;
  lng:any;
  ticket_detail:any;

  constructor(private formBuilder: FormBuilder, private entity_service:EntityService, private user_service:UsersService){ 
    this.locationToSend = "";
  }
  
  ngOnInit(){
    this.profile_form = this.formBuilder.group({
      name: ['', Validators.required],
      address: ['', [Validators.required]],
      email: ['', Validators.required],
      phone: ['',[Validators.minLength(8), Validators.maxLength(8)]],
      departament: [''],
      lat: [''],
      lng: [''],
    });

    this.user_service.get_departaments().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        this.departaments = response.data;
      },
      error: (err:any)=>{
        console.error("Error getting departaments, line 49: ",err);
      }
    });

    this.load_user_information();
  }

  getLocation(location: string) {
    this.lat = location.split(',')[0];
    this.lng = location.split(',')[1];
    console.log("RECIBIDO: ",this.lat,this.lng);
    this.profile_form.controls['lat'].patchValue(this.lat);
    this.profile_form.controls['lng'].patchValue(this.lng);
  }

  load_user_information(){
    this.entity_service.get_entity_information().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ", response);
        this.profile_form.controls['name'].patchValue(response.data[0].name);
        this.profile_form.controls['address'].patchValue(response.data[0].address);
        this.profile_form.controls['email'].patchValue(response.data[0].email);
        this.profile_form.controls['phone'].patchValue(response.data[0].phone);
        this.profile_form.controls['departament'].patchValue(this.departaments.find((d:any) => d.id == response.data[0].depto).name);
        this.profile_form.controls['lat'].patchValue(response.data[0].lat);
        this.profile_form.controls['lng'].patchValue(response.data[0].lng);
        this.lat = response.data[0].lat;
        this.lng = response.data[0].lng;
        if(this.lat && this. lat){
          this.locationToSend = this.lat+','+this.lng;
        }
      },
      error: (err:any) =>{
        if(err.status == 403 || err.status == 401){
          this.user_service.redirect_to_login();
        }
        console.error("Error getting user information, line 68: ",err);
      }
    });
  }

  send_ticket(){
    console.log(this.ticket_detail);
    if(this.ticket_detail){
      this.entity_service.create_ticket({data: this.ticket_detail}).subscribe({
        next: ()=>{
          this.ticket_detail = "";
          this.entity_service.openDialog("¡Creado!","El ticket fue creado para su revisión","../../../assets/images/corazon_rojo.png");
        },
        error: (err:any) => {
          console.error("Error creating ticket", err);
          this.entity_service.openDialog("Error","Error al cargar sus tickets","../../../assets/images/error.png");
        }
      });
    }
    else{
      this.entity_service.openDialog("Incompleto","Ingrese la descripción del ticket","../../../assets/images/error.png");
    }
  }
}
