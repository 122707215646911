import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent {

  profile_form: FormGroup;
  departaments: any;
  blood_types: any;
  locationToSend:any;
  lat:any;
  lng:any;
  user_state: string;
  notification_sms: any;
  notification_push: any;
  notification_email: any;
  can_donate: boolean;

  constructor(private formBuilder: FormBuilder, private user_service:UsersService){ 
    this.user_state = "Potencial donante";
    this.locationToSend = "";
    this.can_donate = true;
  }
  
  ngOnInit(){
    this.profile_form = this.formBuilder.group({
      name: ['', Validators.required],
      lastname: ['', [Validators.required]],
      email: ['', Validators.required],
      phone: ['',[Validators.minLength(8), Validators.maxLength(8)]],
      blood_type: [''],
      age: [''],
      birthday: [''],
      weight: [''],
      sex:[''],
      departament: [''],
      sms_notification: [1],
      email_notification: [1],
      push_notification: [1],
      lat: [''],
      lng: [''],
    });

    this.user_service.get_departaments().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        this.departaments = response.data;
      },
      error: (err:any)=>{
        console.error("Error getting departaments, line 49: ",err);
      }
    });

    this.user_service.get_blood_types().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        this.blood_types = response.data;
      },
      error: (err:any)=>{
        console.error("Error getting blood types, line 59: ",err);
      }
    });

    this.load_user_information();
  }

  

  onSubmit() {
    if (this.profile_form.valid) {
      let data = {
        name: this.profile_form.controls['name'].value,
        lastname: this.profile_form.controls['lastname'].value,
        email: this.profile_form.controls['email'].value,
        phone: this.profile_form.controls['phone'].value,
        blood_type: this.profile_form.controls['blood_type'].value,
        age: this.profile_form.controls['age'].value,
        birthday: this.profile_form.controls['birthday'].value,
        weight: this.profile_form.controls['weight'].value,
        sex: this.profile_form.controls['sex'].value,
        departament: this.profile_form.controls['departament'].value,
        notification_sms: this.notification_sms,
        notification_email: this.notification_email,
        lat: this.lat,
        lng: this.lng,
        //notification_push: this.notification_push,
      }

      console.log("Data a enviar: ",data);

      this.user_service.update_profile(data).subscribe({
        next: (response:any)=>{
          console.log(response)
          if(response.status){ 
            this.user_service.openDialog("¡Información actualizada!","Su información ha sido actualizada exitosamente","../../../assets/images/corazon_rojo.png");
            
            this.user_service.update_donor_status().subscribe({
              next: (response:any)=>{ 
                console.log("donor status updated", response);
                this.load_user_information();
              },
              error: (err:any)=>{
                console.error("Error on updating user donor status, line 106: ",err);
                this.load_user_information();
              }
            });
            
          }
        },
        error: (err:any)=>{
          console.error("Error on create new user, line 112: ",err);
          this.user_service.openDialog("Error","Error al actualizar su información, intente mas tarde","../../../assets/images/error.png");
        }
      });
      
    } 
    else {
      console.log('Formulario inválido',this.profile_form);
      this.user_service.openDialog("Error","Llene correctamente el formulario","../../../assets/images/error.png");
    }
  }

  getLocation(location: string) {
    this.lat = location.split(',')[0];
    this.lng = location.split(',')[1];
    console.log("RECIBIDO: ",this.lat,this.lng);
    this.profile_form.controls['lat'].patchValue(this.lat);
    this.profile_form.controls['lng'].patchValue(this.lng);
  }

  change_notification(type:any){
    
    if(type == 1){
      console.log(this.notification_sms, typeof(this.notification_sms));
      if(this.notification_sms){
        this.notification_sms = 0;
      }
      else{
        this.notification_sms = 1;
      }
    }
    else if(type == 2){
      console.log(this.notification_email, typeof(this.notification_email));
      if(this.notification_email){
        this.notification_email = 0;
      }
      else{
        this.notification_email = 1;
      }
    }
  }
  load_user_information(){
    this.user_service.get_user_information().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ", response);
        this.profile_form.controls['name'].patchValue(response.data[0].first_name);
        this.profile_form.controls['lastname'].patchValue(response.data[0].last_name);
        this.profile_form.controls['email'].patchValue(response.data[0].email);
        this.profile_form.controls['phone'].patchValue(response.data[0].phone);
        this.profile_form.controls['blood_type'].patchValue(response.data[0].blood_type);
        this.profile_form.controls['age'].patchValue(response.data[0].age);
        this.profile_form.controls['birthday'].patchValue(response.data[0].birthday?.split('T')[0]);
        this.profile_form.controls['weight'].patchValue(response.data[0].weight);
        this.profile_form.controls['sex'].patchValue(response.data[0].sex);
        this.profile_form.controls['departament'].patchValue(response.data[0].origin_depto);
        this.profile_form.controls['sms_notification'].patchValue(response.data[0].sms_notification);
        this.profile_form.controls['email_notification'].patchValue(response.data[0].email_notification);
        this.profile_form.controls['push_notification'].patchValue(response.data[0].push);
        this.notification_sms = response.data[0].sms_notification;
        this.notification_email = response.data[0].email_notification;
        this.notification_push = response.data[0].push;
        this.profile_form.controls['lat'].patchValue(response.data[0].lat);
        this.profile_form.controls['lng'].patchValue(response.data[0].lng);
        this.lat = response.data[0].lat;
        this.lng = response.data[0].lng;
        if(this.lat && this. lat){
          this.locationToSend = this.lat+','+this.lng;
        }

        if(response.data[0].donor_status){
          this.can_donate = true;
        }
        else{
          this.can_donate = false;
        }
      },
      error: (err:any) =>{
        if(err.status == 403 || err.status == 401){
          this.user_service.redirect_to_login();
        }
        console.error("Error getting user information, line 68: ",err);
      }
    });
  }
}
