import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent {
  
  //tipo para mostrar diferentes partes de la landing
  // 1: inicio 
  // 2: sobre el proyecto 
  // 3: faq 
  // 4: entidades
  // 5: deberes y derechos 
  // 6: autoexamen
  // 7: solicitudes
  // 8: crecion de solicitudes
  type:number;
  faq:number;
  @ViewChild(MapComponent) map: MapComponent;

  constructor(private router:Router){
    this.type = 1;
    this.faq = 0;
  }

  changeType(type:number){
    this.type = type
    if(type == 4){
      this.map.loadPlaces();
    }
  }
  iniciarSesion(){
    this.router.navigate(['/login']);
  }
  navigateHome(){
    this.router.navigate(['/'])
  }
  Registrarse(type:number){
    if(type==1) this.router.navigate(['/registrar'])
    if(type==2) this.router.navigate(['/registrar/institucion'])
  }
  changeFAQ(to:number){
    this.faq = to;
  }
}