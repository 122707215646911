import { Component } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-autoexamen',
  templateUrl: './autoexamen.component.html',
  styleUrls: ['./autoexamen.component.css']
})
export class AutoexamenComponent {

  age:any;
  weight:any;
  donation:any;
  health:any;
  infection:any;
  surgery:any;
  hepatitis:any;
  pregnancy:any;
  breastfeeding:any;
  alcohol:any;
  last_update:any;

  constructor(public dialog: MatDialog, private service:UsersService, private router:Router){
    this.age=0;
    this.weight=0;
    this.donation=0;
    this.health=0;
    this.infection=0;
    this.surgery=0;
    this.hepatitis=0;
    this.pregnancy=0;
    this.breastfeeding=0;
    this.alcohol=0;
    this.last_update = undefined;
  }

  ngOnInit(){
    this.load_data();
  }

  onSubmit(){
    let alert = false;
    let title = ""
    let desc = "";
    let type = 1;
    if(this.router.url === '/'){
      type = 2;
    }

    if(
      this.age &&
      this.weight &&
      this.donation &&
      this.health &&
      this.infection &&
      this.surgery &&
      this.hepatitis &&
      this.pregnancy &&
      this.breastfeeding &&
      this.alcohol
    ){
      
      if(this.age>1){ desc +="No cumples con el requisito de edad\n"; alert = true;}
      if(this.weight>1){ desc +="Debes pesar al menos 110 libras\n"; alert = true;}
      if(this.donation<2){ desc +="Has donado sangre recientemente\n"; alert = true;}
      if(this.health<2){ desc +="Padeces de enfermedades sanguíneas\n"; alert = true;}
      if(this.infection<2){ desc +="Padeciste de una infección recientemente\n"; alert = true;}
      if(this.surgery<2){ desc +="Has sido intervenido quirurjicamente recientemente\n"; alert = true;}
      if(this.hepatitis<2){ desc +="Has padecido hepatitis luego de los 12 años\n"; alert = true;}
      if(this.pregnancy<2){ desc +="Durante el embarazo o en los 6 meses posteriores es recomedable no donar\n"; alert = true;}
      if(this.breastfeeding<2){ desc +="No es recomendable donar en periodo de lactancia\n"; alert = true;}
      if(this.alcohol<2){ desc +="Antes de donar, debes asegurarte tener 24 horas de no haber ingerido alcohol."}

      const data = {age: this.age,
        weight: this.weight,
        donation: this.donation,
        health: this.health,
        infection: this.infection,
        surgery: this.surgery,
        hepatitis: this.hepatitis,
        pregnancy: this.pregnancy,
        breastfeeding: this.breastfeeding,
        alcohol: this.alcohol,
        status: 0
      }
      if(alert){
        let arr = desc.split("\n");
        arr = arr.filter(element => element);
        title = "Lamentablemente en este momento parece que no puedes donar sangre, estos prodian ser los motivos:"
        this.service.openDialog(title,arr,"../../../assets/images/corazon_gris.png",type,JSON.stringify(data));
      }
      else{
        data.status = 1;
        title = "¡Felicidades, parece que eres un potencial dontante de sangre!"
        if(this.alcohol<2) desc+=" ¡Pero eres apto para donar!"
        this.service.openDialog(title,desc,"../../../assets/images/corazon_rojo.png",type,JSON.stringify(data));
      }

      //this.openDialog(title,desc,imgUrl);
      this.last_update = new Date();
    }
    else{
      console.log("Faltan respuestas");
      this.service.openDialog("Por favor, responda todas las preguntas","Para continuar, responda cada una de las preguntras","../../../assets/images/error.png");
    }
  }

  load_data(){
    this.service.get_autoexam().subscribe({
      next: (response:any) => {
        console.log(response);
        if(response.data[0]){
          let autoexam = JSON.parse(response.data[0].responses);
          this.age = autoexam.age;
          this.weight = autoexam.weight;
          this.donation = autoexam.donation;
          this.health = autoexam.health;
          this.infection = autoexam.infection;
          this.surgery = autoexam.surgery;
          this.hepatitis = autoexam.hepatitis;
          this.pregnancy = autoexam.pregnancy;
          this.breastfeeding = autoexam.breastfeeding;
          this.alcohol = autoexam.alcohol;
          this.last_update = response.data[0].date;
        }
      },
      error: (err:any) => {
        console.error("Error getting autoexam, line 45", err);
      }
    });
  }
}
