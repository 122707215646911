<div style="display: flex; margin-bottom: 15px;">
    <div class="row" style="justify-content: space-between; width: 100%;">
        <div class="col-5">
            <span *ngIf="orderAsc" (click)="ordenar(1)" style="cursor: pointer;"><i class="fa-solid fa-arrow-up" (click)="ordenar(1)"></i> Mas antiguos primero</span>
            <span *ngIf="!orderAsc" (click)="ordenar(2)" style="cursor: pointer;"><i class="fa-solid fa-arrow-down" (click)="ordenar(2)"></i>  Mas recientes primero</span>  
        </div>
        <div class="col-5" style="display: flex; justify-content: flex-end;">
            <button class="filter-button" data-bs-toggle="modal" data-bs-target="#filter-modal">Filtros</button>
        </div>
    </div>
</div>

<ng-container *ngFor="let p of peticiones">   
    <div class="petition-card" *ngIf="p.type == 1">
        <div style="width: 3%; height: auto; background-color: #9197AE;"></div>
        <div style="width: 97%; padding: 15px; background-color: #F1F1F1;">
            <div style="display: flex; justify-content: space-between;">
                <p><b>{{p.title}}</b></p>
            </div>
            <p>{{p.description}}</p>
            <hr>
            <div style="display: flex; justify-content: space-between;">    
                <div style="display: flex; gap: 35px; justify-content: flex-start; align-items: baseline;">
                    <span><i class="fa-solid fa-droplet"  matTooltip="Tipo de sangre requerido"></i>{{p.blood_type}}</span>
                    <span><i class="fa-regular fa-calendar" matTooltip="Fecha creación"></i>{{p.date_created}}</span>
                    <span><i class="fa-solid fa-phone"  matTooltip="Contacto"></i>{{p.phone}}</span> 
                    <!-- <span><i [ngClass]="{'icon-blue': p.showMap, 'icon-black': !p.showMap}" class="fa-solid fa-location-dot" matTooltip="Ver ubicación" style="cursor: pointer;" (click)="toggleMap(p)"></i>{{p.entity}}</span> -->
                </div>
                <div>
                    <button style="background: none; border: none;" (click)="delete_petition(p)">
                        <i class="fa-solid fa-trash-can" matTooltip="Eliminar" style="align-self: flex-end; cursor: pointer;"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- <div style="width: 100%; background-color: mintcream;" 
         *ngIf="p.type == 1" [ngClass]="{'mapVisible': p.showMap, 'mapHidden' : !p.showMap }">
        <app-map [locationSended_auto]="p.showMap" [index]="p.id"></app-map>
    </div>  -->
    <div class="petition-card" *ngIf="p.type == 2">
        <div style="width: 3%; height: auto; background-color: #9197AE;"></div>
        <div style="width: 97%; padding: 15px; background-color: #F1F1F1;">
            <div style="display: flex; justify-content: space-between;">
                <p><b>{{p.title}}</b></p>
            </div>
            <p>{{p.description}}</p>
            <span><i class="fa-regular fa-clock" matTooltip="Hora jornada"></i>{{p.date_start | date: 'medium'}}-{{p.date_end | date: 'medium'}}</span>
            <hr>
            <div style="display: flex; justify-content: space-between;">
                <div style="display: flex; gap: 35px; justify-content: flex-start; align-items: baseline;">              
                    <span><i class="fa-regular fa-calendar" matTooltip="Fecha creación"></i>{{p.date_created}}</span>
                    <span><i class="fa-solid fa-phone"></i>{{p.phone}}</span>
                    <!-- <span><i [ngClass]="{'icon-blue': p.showMap, 'icon-black': !p.showMap}" class="fa-solid fa-location-dot" matTooltip="Ver ubicación" style="cursor: pointer;" (click)="toggleMap(p)"></i>{{p.entity}}</span> -->
                </div>
                <div>
                    <button style="background: none; border: none;" (click)="delete_petition(p)">
                        <i class="fa-solid fa-trash-can" matTooltip="Eliminar" style="align-self: flex-end; cursor: pointer;"></i>
                    </button>
                </div>
            </div>
            
        </div> 
    </div>
    <!-- <div style="width: 100%; background-color: mintcream;" 
         *ngIf="p.type == 2" [ngClass]="{'mapVisible': p.showMap, 'mapHidden' : !p.showMap }">
        <app-map [locationSended_auto]="p.showMap" [index]="p.id"></app-map>
    </div> -->
</ng-container>