<div class="register-container">
    <div class="row justify-content-center" style="width: 100%;">
      <div class="col-10 col-md-9 form-filled">
        <div style="width: 100%;">
            <div class="row">
                <div class="col-12" style="display: flex; justify-content: center;">
                    <p style="font-size: 2.5vw; font-weight: bold; color: #003FB9;">Tickets a resolver</p>
                </div>
            </div>       
        </div>
        <ng-container *ngFor="let ticket of tickets">
            <div class="petition-card" [ngClass]="{'bkwhite': ticket.status == 1, 'bkgreen': ticket.status == 2, 'bkred': ticket.status == 3 }">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <span><strong>Institución:</strong> {{ticket.name}}</span>
                        </div>
                        <div class="row">
                            <span><strong>ID:</strong> {{ticket.id_user}}</span>
                        </div>
                        <hr>
                        <div class="row">
                            <span><strong>Descripción:</strong> {{ticket.text}}</span>
                        </div>
                        <div class="row">
                            <span><strong>Fecha de creación:</strong> {{ticket.date | date:'medium'}}</span>
                        </div>
                        <div class="row">
                            <span><strong>Status:</strong> {{ticket.status == 1? "Abierto" : "Resuelto"}}</span>
                        </div>
                        <div class="row" *ngIf="ticket.status != 1">
                            <span><strong>Comentario:</strong> {{ ticket.comment }}</span>
                        </div>
                    </div>
                    
                </div>
                <div class="row" style="display: flex;">
                    <div class="col-12 col-md-6" style="align-items: center; display: flex; justify-content: center;">
                        <button class="btn btn-primary" style="cursor: pointer;" (click)="accept_ticket(ticket)">Aceptar</button>
                    </div>
                    <div class="col-12 col-md-6" style="align-items: center; display: flex; justify-content: center;">
                        <button class="btn btn-primary" style="cursor: pointer;" (click)="delete_ticket(ticket)">Denegar</button>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-12">
                        <span><strong>Status:</strong> {{ticket.status == 1? "Abierto" : "Resuelto"}}</span>
                    </div>
                </div>
                <div class="row" *ngIf="ticket.comment">
                    <div class="col-12">
                        <span><strong>Comentario:</strong> {{ ticket.comment }}</span>
                    </div>
                </div> -->
            </div>
        </ng-container>       
      </div>
    </div>
</div>
