import { Component } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import {MatDialog } from '@angular/material/dialog';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import { Router } from '@angular/router';

interface Filter {
  blood_types: string[];
  department: string;
  start_date: Date;
  end_date: Date;
  by_compatibility?: { [key: string]: any };
  [key: string]: any;
}
@Component({
  selector: 'app-petitions-list',
  templateUrl: './petitions-list.component.html',
  styleUrls: ['./petitions-list.component.css']
})


export class PetitionsListComponent {

  peticiones:any;
  orderAsc:boolean;
  locationToSend:any;
  lat:any;
  lng:any;

  //filtros:
  tiposSangreSeleccionados: string[] = [];
  km_filter: any;
  departaments: any;
  depto_filter: any;
  date_start: any;
  date_end: any;
  blood_types:any;

  //complaints
  petition_selected:any;
  complaint_detail:string;

  //history
  event_date_history:any;
  petition_selected_history:any;

  constructor(private service:UsersService, public dialog: MatDialog, private router:Router){
    this.peticiones = [];
    this.orderAsc = false;
    this.departaments = [];
    this.depto_filter = "";
    this.date_start = undefined;
    this.date_end = undefined;
    this.blood_types = [];
    this.petition_selected = undefined;
    this.petition_selected_history = undefined;
    this.complaint_detail = "";
    this.event_date_history = undefined;
  }

  ngOnInit(){
    this.service.get_departaments().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        this.departaments = response.data;
      },
      error: (err:any)=>{
        console.error("Error getting departaments, line 52: ",err);
      }
    });

    this.service.get_blood_types().subscribe({
      next: (response:any) => {
        console.log("Tipos de sangre devuletos: ",response);
        this.blood_types = response.data;
      },
      error: (err:any) => {
        console.error("Error getting departaments, line 74: ", err);
      } 
    });

    this.service.get_requests().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        this.peticiones = response.data.map((element:any)=>{
          console.log(element);
          element.date_created = element.date_created.split('T')[0];
          if(element.date_start)element.date_start = element.date_start.replace('T',' ').split('.')[0];
          if(element.date_end)element.date_end = element.date_end.replace('T',' ').split('.')[0];
          if(element.blood_type)element.blood_type = element.blood_type.replaceAll(",",", ");
          element.showMap = "";
          return element;
        });
      //   setTimeout(()=>{
      //     this.peticiones.forEach((element: any) => {
      //       this.closeIndividualMap(element);
      //     });
      // }, 2000);
        
      },
      error: (err:any)=>{
        console.error("Error getting requests, line 43: ",err);
        this.service.openDialog("Error","Error al obtener las peticiones, intente mas tarde","../../../assets/images/error.png")
      }
    });

  }

  ngAfterViewInit(): void {
    
  }
  
  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(FilterDialogComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
    
  }

  ordenarPorFecha(arr:any, ascendente = false) {
  const sortedArray = arr.slice(); // Copia el arreglo original para evitar modificarlo directamente

  sortedArray.sort((a:any, b:any) => {
    //console.log(a.date_created,b.date_created);
    const fechaA = new Date(a.date_created).getTime();
    const fechaB = new Date(b.date_created).getTime();

    if (ascendente) {
      return fechaA - fechaB; // Orden ascendente
    } else {
      return fechaB - fechaA; // Orden descendente
    }
  });

  console.log(sortedArray);

  return sortedArray;
  }

  ordenar(tipo:any){
    console.log("Ordenando tipo: ",tipo)
    if(tipo==1){
      this.peticiones = this.ordenarPorFecha(this.peticiones, false)
      this.orderAsc = false;
    }
    else{
      this.peticiones = this.ordenarPorFecha(this.peticiones, true);
      this.orderAsc = true;
    }
  }

  toggleMap(petition:any){
    if(petition.showMap){
      this.closeIndividualMap(petition);
    }
    else{
      this.showIndividualMap(petition);
    }
  }

  showIndividualMap(peticion:any){
    console.log("RECIBO: ",peticion);
    this.locationToSend = peticion.lat+","+peticion.lng;
    peticion.showMap = peticion.lat+","+peticion.lng;
    console.log("SETEAR: ",this.locationToSend);
  }

  closeIndividualMap(peticion:any){
    peticion.showMap = false;
  }

  filter() {
    console.log(this.tiposSangreSeleccionados,this.km_filter,this.depto_filter, this.date_start, this.date_end);
    let filter: Filter = {
      "blood_types": this.tiposSangreSeleccionados,
      "department": this.depto_filter,
      "start_date": this.date_start,
      "end_date": this.date_end,
      //"by_compatibility": "O-",
      // "by_distance": 6,
      // "lat": 14.636962122177424,
      // "lng": -90.5853340955348 
    }

    if(this.lat && this. lat){
      this.locationToSend = this.lat+','+this.lng;
      if(this.km_filter){
        filter['by_distance'] = this.km_filter;
        filter['lat'] = this.lat;
        filter['lng'] = this.lng;
      }
    }

    

   console.log("Filtro: ",filter)
    this.service.get_requests_filtered(filter).subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        this.peticiones = response.data.map((element:any)=>{
          element.date_created = element.date_created.split('T')[0];
          if(element.date_start)element.date_start = element.date_start.replace('T',' ').split('.')[0];
          if(element.date_end)element.date_end = element.date_end.replace('T',' ').split('.')[0];
          if(element.blood_type)element.blood_type = element.blood_type.replaceAll(",",", ");
          element.showMap = "";
          return element;
        });
      },
      error: (err:any)=>{
        console.error("Error getting departaments, line 169: ",err);
      }
    });
  }

  filter_() {
    console.log(this.tiposSangreSeleccionados,this.km_filter,this.depto_filter, this.date_start, this.date_end);
    this.service.get_user_information().subscribe({
      next: (response:any)=>{
        console.log("RESPUESTA: ",response);
        let user_data = response.data[0];
        
        let user_blood_type = this.blood_types.find((bt:any) => bt.id == user_data.blood_type);
        
        let filter: Filter = {
          blood_types: this.tiposSangreSeleccionados,
          department: this.depto_filter,
          start_date: this.date_start,
          end_date: this.date_end,
          by_compatibility: {}
        };

        if(user_blood_type && filter.by_compatibility){
          filter.by_compatibility['blood_type'] = user_blood_type.name;
        }

       if(user_data.origin_depto && filter.by_compatibility){
          filter.by_compatibility['departament'] = user_data.origin_depto;
       }

       console.log("filtro: ",filter);

        this.service.get_requests_filtered(filter).subscribe({
          next: (response:any) => {
            console.log("Respuesta: ",response);
            this.peticiones = response.data.map((element:any)=>{
              element.date_created = element.date_created.split('T')[0];
              if(element.date_start)element.date_start = element.date_start.replace('T',' ').split('.')[0];
              // if(element.date_end)element.date_end = element.date_end.split('T')[1].split('.')[0];
              if(element.date_end)element.date_end = element.date_end.replace('T',' ').split('.')[0];
              if(element.blood_type)element.blood_type = element.blood_type.replaceAll(",",", ");
              element.showMap = "";
              return element;
            });
          },
          error: (err:any)=>{
            console.error("Error getting departaments, line 200: ",err);
          }
        });
      },
      error: (err:any)=>{
        console.error("Error getting departaments, line 172: ",err);
      }
    });
    
  }

  toggleTipoSangre(tipo: string) {
    const index = this.tiposSangreSeleccionados.indexOf(tipo);
    if (index === -1) {
      this.tiposSangreSeleccionados.push(tipo.toUpperCase());
    } else {
      this.tiposSangreSeleccionados.splice(index, 1);
    }
  }

  clean_filter(){
    this.tiposSangreSeleccionados = [];
    this.km_filter = -1;
    this.date_start = undefined;
    this.date_end = undefined;
    this.depto_filter = "";
    // this.locationToSend = -1;
    this.lat = undefined;
    this.lng = undefined;
    this.km_filter = undefined;
  }

  getLocation(location: string) {
    this.lat = location.split(',')[0];
    this.lng = location.split(',')[1];
    console.log("RECIBIDO: ",this.lat,this.lng);
  }

  add_petition_to_history(){
    if(this.router.url === '/'){
      this.service.openDialog("Ups...","Esta función es para usuarios registrados, registrate para poder acceder a esta y otras funciones","../../../assets/images/corazon_gris.png");
      return;
    }
    console.log("Agregar al historial", this.petition_selected_history);
    this.petition_selected_history.date_event = this.event_date_history;
    this.service.add_into_history(this.petition_selected_history).subscribe({
      next:(response:any) => {
        console.log(response);
          this.service.openDialog("¡Agregada!","La petición fue agregada al historial","../../../assets/images/corazon_rojo.png");
          this.peticiones = this.peticiones.map((p:any)=>{
            if(p.id == this.petition_selected_history.id){
              p.saved_in_history = 1;
            }
            return p;
          });
      },
      error: (err:any)=>{
        console.error("Error adding petition to history, line 289: ",err);
        
        if(err.status == 403 || err.status == 401){
          this.service.openDialog("Error","Error al agregar peticion, su sesión expiró","../../../assets/images/error.png");
          this.service.redirect_to_login();
        }
        else{
          this.service.openDialog("Error","Error al agregar peticion, intente mas tarde","../../../assets/images/error.png");
        }
      }
    });
  }

  add_petition_to_interested(petition:any){
    if(this.router.url === '/'){
      this.service.openDialog("Ups...","Esta función es para usuarios registrados, registrate para poder acceder a esta y otras funciones","../../../assets/images/corazon_gris.png");
      return;
    }
    console.log("Agregar a me interesa", petition);
    this.service.add_into_interest(petition).subscribe({
      next: (response:any) => {
        console.log(response);
        this.service.openDialog("¡Agregada!","La petición fue agregada a eventos de interes","../../../assets/images/corazon_rojo.png");
        this.peticiones = this.peticiones.map((p:any)=>{
          if(p.id == petition.id){
            p.saved_in_interest = 1;
          }
          return p;
        });
      },
      error: (err:any) => {
        console.error("Error adding petition to history, line 319: ",err);
        if(err.status == 403 || err.status == 401){
          this.service.openDialog("Error","Error al agregar peticion, su sesión expiró","../../../assets/images/error.png");
          this.service.redirect_to_login();
        }
        else{
          this.service.openDialog("Error","Error al agregar peticion, intente mas tarde","../../../assets/images/error.png");
        }
      }
    });
  }

  set_complaint(petition:any){
    this.petition_selected = {...petition};
  }

  set_petition(petition:any){
    console.log("perticion setterada")
    this.petition_selected_history = {...petition};
  }

  send_complaint(){
    if(this.router.url === '/'){
      this.service.openDialog("Ups...","Esta función es para usuarios registrados, registrate para poder acceder a esta y otras funciones","../../../assets/images/corazon_gris.png");
      return;
    }
    console.log("peticion: ",this.petition_selected);
    console.log("motivo:", this.complaint_detail);
    this.service.save_complaint({reason: this.complaint_detail, request: this.petition_selected.id}).subscribe({
      next: (response:any) => {
        console.log(response);
        this.peticiones = this.peticiones.map((p:any)=>{
          console.log("p es:",p)
          if(p.id == this.petition_selected.id){
            p.already_reported = 1;
          }
          return p;
        });
        this.service.openDialog("¡Enviada!","La denuncia fue enviada, un administrador revisará la petición. Gracias por aportar a la comunidad.","../../../assets/images/corazon_rojo.png");
      },
      error: (err:any) => {
        console.error("Error al guardar la denuncia", err);
        this.service.openDialog("Error","Error al guardar la denuncia, intente mas tarde","../../../assets/images/error.png")
      }
    });
  }
}
