import { Component } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-new-entities',
  templateUrl: './new-entities.component.html',
  styleUrls: ['./new-entities.component.css']
})
export class NewEntitiesComponent {
  
  entities:any;
  departaments:any;
  locationToSend:any;
  
  constructor(private service:UsersService, private admin_service: AdminService){
    this.entities = [];
    this.departaments = [];
  }


   ngOnInit(){
     this.service.get_departaments().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        this.departaments = response.data;
      },
      error: (err:any)=>{
        console.error("Error getting departaments, line 52: ",err);
      }
    });

  }

  ngAfterViewInit(){
    this.admin_service.get_new_entities().subscribe({
      next: (response:any) => {
        console.log(response);
        this.entities = response.data.map((element:any)=>{
          console.log(element);
          element.depto_text = this.departaments.find((d:any) => d.id == element.depto).name;
          element.showMap = "";
          return element;
        });
        console.log(this.entities);
        setInterval(() => {
          this.entities.forEach((element:any) => {
            element.showMap = element.lat+","+element.lng
          });
       }, 500);
        
      },
      error: (err:any) =>{
        this.admin_service.openDialog("Error","Error al cargar datos de instituciones nuevas","../../../assets/images/error.png");
        console.error("error al obtener datos de instuticones nuevas.");
      }
    });
  }

  update_new_entity(entity:any){
    Swal.fire({
      title: "Aceptar institución",
      text: "Al aceptar la solicitud se habilitara el ingreso del usuario a la aplicación.",
      // input: "text",
      // inputAttributes: {
      //   autocapitalize: "off"
      // },
      showCancelButton: true,
      confirmButtonText: "Guardar",
      denyButtonText: `Cancelar`,
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if(result.isDismissed){
        return;
      }
      let data = { 
        comment: result.value, 
        id: entity.id,
        email: entity.email
      }

      this.admin_service.update_new_entities(data).subscribe({
        next: (resp:any) => {
          this.admin_service.openDialog("Aceptada","La solicitud fue aceptada.","../../../assets/images/corazon_rojo.png");
          this.entities = this.entities.filter((t:any)=> t.id != entity.id);
        },
        error: (err:any) => {
          this.admin_service.openDialog("Error","Error al rechazar la solicitud","../../../assets/images/error.png");
        }
      });
    });
  }

  reject_new_entity(entity:any){
    Swal.fire({
      title: "Denegar Solicitud",
      text: "Ingrese un comentario",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Guardar",
      denyButtonText: `Cancelar`,
      showLoaderOnConfirm: true,
      preConfirm: async (comment) => {
        if(!comment){
          Swal.showValidationMessage(`
            Ingrese un comentario
          `);
        }
        else{
          return comment;
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if(result.isDismissed){
        return;
      }
      let data = {
        status: 3, 
        comment: result.value, 
        id: entity.id,
        email: entity.email
      }
      // console.log("rechazado: ",data)
      this.admin_service.reject_new_entities(data).subscribe({
        next: (resp:any) => {
          this.admin_service.openDialog("Rechazada","La solicitud fue rechazada, se enviaran los comentarios al correo registrado.","../../../assets/images/corazon_rojo.png");
          this.entities = this.entities.filter((t:any)=> t.id != entity.id);
        },
        error: (err:any) => {
          this.admin_service.openDialog("Error","Error al cerrar el ticket","../../../assets/images/error.png");
        }
      });
    });
  }
}
