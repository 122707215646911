<div class="register-container">
    <div class="row justify-content-center" style="width: 100%;">
      <div class="col-10 col-md-9 form-filled">
        <div style="width: 100%;">
            <div class="row">
                <div class="col-12" style="display: flex; justify-content: center;">
                    <p style="font-size: 2.5vw; font-weight: bold; color: #003FB9;">Perfil</p>
                </div>
            </div>       
        </div>
        
        <form [formGroup]="profile_form">
          <div class="row" style="gap: 15px 0px;">
            <div class="form-group col-12 col-lg-6">
                <label for="name">Nombre</label>
                <input type="text" formControlName="name" placeholder="*Nombre de la institución" class="form-control" readonly style="cursor: not-allowed;">
            </div>
            <div class="form-group col-12 col-lg-6">
                <label for="address">Dirección</label>
                <input type="text" formControlName="address" placeholder="*Dirección" class="form-control" readonly style="cursor: not-allowed;">
            </div>
            <div class="form-group col-12 col-lg-6">
                <label for="email">Correo Electrónico</label>
                <input type="text" formControlName="email" placeholder="Email" class="form-control" readonly style="cursor: not-allowed;">
            </div>
            
            <div class="form-group col-12 col-lg-6">
                <label for="phone">Teléfono</label>
                <input type="text" formControlName="phone" placeholder="Teléfono" class="form-control" readonly style="cursor: not-allowed;">
            </div>

            <div class="form-group col-12 col-lg-6">
                <label for="departament">Departamento</label>
                <input type="text" formControlName="departament" placeholder="Departamento" class="form-control" readonly style="cursor: not-allowed;">
            </div>
            <!-- <div class="form-group col-12 col-lg-6" aria-readonly="true">
                <label for="departament">Departamento</label>
                <select formControlName="departament" class="form-select" style="cursor: not-allowed;">
                    <option *ngFor="let depto of departaments" [ngValue]="depto.id">{{depto.name}}</option>
                </select>
            </div> -->
          </div>
          <div class="row" style="margin: 15px 0px 15px 0px;">
            <div class="form-group col-12" style="min-height: 500px;">
                <app-map (locationEvent)="getLocation($event)" [locationSended]="locationToSend" [index]="1"></app-map>
                
                <div class="row" style="justify-content: center; align-items: center; margin-top: 20px; gap: 20px;">
                </div>

                <div class="row">
                </div>
            </div>
          </div>   
          <div class="row">
            <button data-bs-toggle="modal" data-bs-target="#ticket-modal" class="btn btn-primary" style="margin-bottom: 20px;">
                Crear Ticket
            </button>
          </div>
        </form>
      </div>
    </div>
</div>

<!-- TICKET MODAL -->
<div class="modal fade custom-fade" id="ticket-modal" tabindex="-1" aria-labelledby="filters" aria-hidden="true" >
    <div class="modal-dialog  modal-dialog-centered" style="max-width: 45vw;">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: none;">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="padding-left: 10%; padding-right: 10%;">
            <h2>Nuevo Ticket</h2>
            <textarea class="form-control" aria-label="ticket detail" [(ngModel)]="ticket_detail" placeholder="Describe la solicitud a realizar..."></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="send_ticket()" data-bs-dismiss="modal">Enviar</button>
        </div>
      </div>
    </div>
</div>