
<div style="display: flex; gap: 10px; align-items: center; margin: 20px;">
    <div style="background-color: #F02D3A; width: 1rem; height: 1rem; border-radius: 6px;"></div>
    <span>Peticiones</span>
    <div style="background-color: #9197AE; width: 1rem; height: 1rem; border-radius: 6px;"></div>
    <span>Jornadas de institución</span>
</div>
<ng-container *ngFor="let p of peticiones">
    <div class="petition-card" *ngIf="p.type == 1">
        <div style="width: 3%; height: auto; background-color: #F02D3A;"></div>
        <div style="width: 97%; padding: 15px; background-color: snow;">
            <div style="display: flex; justify-content: space-between;">
                <p><b>{{p.title}}</b></p>
                <div class="dropdown">
                    <button type="button" (click)="delete_from_interest(p)" aria-expanded="false" style="background: none; border: none;">
                        <i class="fa-solid fa-xmark" matTooltip="Eliminar" style="cursor: pointer;"></i>
                    </button>
                </div>
            </div>
            <p>{{p.description}}</p>
            <hr>
            <div style="display: flex; justify-content: space-between;">    
                <div style="display: flex; gap: 35px; justify-content: flex-start; align-items: baseline;">
                    <span><i class="fa-solid fa-droplet"  matTooltip="Tipo de sangre requerido"></i>{{p.blood_type}}</span>
                    <span><i class="fa-regular fa-calendar" matTooltip="Fecha creación"></i>{{p.date_created}}</span>
                    <span><i class="fa-solid fa-phone"  matTooltip="Contacto"></i>{{p.phone}}</span> 
                    <span><i [ngClass]="{'icon-blue': p.showMap, 'icon-black': !p.showMap}" class="fa-solid fa-location-dot" matTooltip="Ver ubicación" style="cursor: pointer;" (click)="toggleMap(p)"></i>{{p.entity}}</span>
                </div>
                <div>
                    <button *ngIf="!p.already_reported" data-bs-toggle="modal" data-bs-target="#complaint-modal" style="background: none; border: none;" (click)="set_complaint(p)">
                        <i  class="fa-solid fa-triangle-exclamation" matTooltip="Denunciar" style="align-self: flex-end; cursor: pointer;"></i>
                    </button>
                    <button *ngIf="p.already_reported" style="background: none; border: none;" >
                        <i class="fa-solid fa-flag" matTooltip="Denunciaste esta publicación" style="align-self: flex-end; cursor: pointer;"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 100%; background-color: mintcream;" 
         *ngIf="p.type == 1" [ngClass]="{'mapVisible': p.showMap, 'mapHidden' : !p.showMap }">
        <app-map [locationSended_auto]="p.showMap" [index]="p.id"></app-map>
    </div>
    
    <div class="petition-card" *ngIf="p.type == 2">
        <div style="width: 3%; height: auto; background-color: #9197AE;"></div>
        <div style="width: 97%; padding: 15px; background-color: #F1F1F1;">
            <div style="display: flex; justify-content: space-between;">
                <p><b>{{p.title}}</b></p>
                <!-- <i class="fa-solid fa-plus" matTooltip="Registrar" style="cursor: pointer;"></i> -->
                <div class="dropdown">
                    <button type="button" (click)="delete_from_interest(p)" aria-expanded="false" style="background: none; border: none;">
                        <i class="fa-solid fa-xmark" matTooltip="Eliminar" style="cursor: pointer;"></i>
                    </button>
                </div>
            </div>
            <p>{{p.description}}</p>
            <span><i class="fa-regular fa-clock" matTooltip="Hora jornada"></i>{{p.date_start}}-{{p.date_end}}</span>
            <hr>
            <div style="display: flex; justify-content: space-between;">
                <div style="display: flex; gap: 35px; justify-content: flex-start; align-items: baseline;">
                    
                    <span><i class="fa-regular fa-calendar" matTooltip="Fecha creación"></i>{{p.date_created}}</span>
                    <span><i class="fa-solid fa-phone"></i>{{p.phone}}</span>
                    <span><i [ngClass]="{'icon-blue': p.showMap, 'icon-black': !p.showMap}" class="fa-solid fa-location-dot" matTooltip="Ver ubicación" style="cursor: pointer;" (click)="toggleMap(p)"></i>{{p.entity}}</span>
            
                </div>
                <div>
                    <button *ngIf="!p.already_reported" data-bs-toggle="modal" data-bs-target="#complaint-modal" style="background: none; border: none;" (click)="set_complaint(p)">
                        <i  class="fa-solid fa-triangle-exclamation" matTooltip="Denunciar" style="align-self: flex-end; cursor: pointer;"></i>
                    </button>
                    <button *ngIf="p.already_reported"  style="background: none; border: none;" >
                        <i class="fa-solid fa-flag" matTooltip="Denunciaste esta publicación" style="align-self: flex-end; cursor: pointer;"></i>
                    </button>
                </div>
            </div>
        </div> 
    </div>
    <div style="width: 100%; background-color: mintcream;" 
         *ngIf="p.type == 2" [ngClass]="{'mapVisible': p.showMap, 'mapHidden' : !p.showMap }">
        <app-map [locationSended_auto]="p.showMap" [index]="p.id"></app-map>
    </div>
</ng-container>

<!-- complaint modal -->
<div class="modal fade custom-fade" id="complaint-modal" tabindex="-1" aria-labelledby="filters" aria-hidden="true" >
    <div class="modal-dialog  modal-dialog-centered" style="max-width: 45vw;">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: none;">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="padding-left: 10%; padding-right: 10%;">
            <h2>Denuncia</h2>
            <textarea class="form-control" aria-label="complaint detail" [(ngModel)]="complaint_detail"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="send_complaint()" data-bs-dismiss="modal">Enviar</button>
        </div>
      </div>
    </div>
</div>