import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityService } from 'src/app/services/entity.service';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-petitions',
  templateUrl: './petitions.component.html',
  styleUrls: ['./petitions.component.css']
})
export class PetitionsComponent {

  peticiones:any;
  orderAsc:boolean;
  locationToSend:any;
  lat:any;
  lng:any;

  //filtros:
  tiposSangreSeleccionados: string[] = [];
  km_filter: any;
  departaments: any;
  depto_filter: any;
  date_start: any;
  date_end: any;
  blood_types:any;

  //complaints
  petition_selected:any;
  complaint_detail:string;

  //history
  event_date_history:any;
  petition_selected_history:any;

  constructor(private service:UsersService, private entity_service:EntityService, public dialog: MatDialog){
    this.peticiones = [];
    this.orderAsc = false;
    this.departaments = [];
    this.depto_filter = "";
    this.date_start = undefined;
    this.date_end = undefined;
    this.blood_types = [];
    this.petition_selected = undefined;
    this.petition_selected_history = undefined;
    this.complaint_detail = "";
    this.event_date_history = undefined;
  }

  ngOnInit(){
    this.service.get_departaments().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        this.departaments = response.data;
      },
      error: (err:any)=>{
        console.error("Error getting departaments, line 52: ",err);
      }
    });

    this.service.get_blood_types().subscribe({
      next: (response:any) => {
        console.log("Tipos de sangre devuletos: ",response);
        this.blood_types = response.data;
      },
      error: (err:any) => {
        console.error("Error getting departaments, line 74: ", err);
      } 
    });

    this.entity_service.get_requests().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        this.peticiones = response.data.map((element:any)=>{
          console.log(element);
          element.date_created = element.date_created.split('T')[0];
          if(element.date_start)element.date_start = element.date_start.replace('T',' ').split('.')[0];
          if(element.date_end)element.date_end = element.date_end.replace('T',' ').split('.')[0];
          if(element.blood_type)element.blood_type = element.blood_type.replaceAll(",",", ");
          element.showMap = "";
          return element;
        });
      //   setTimeout(()=>{
      //     this.peticiones.forEach((element: any) => {
      //       this.closeIndividualMap(element);
      //     });
      // }, 2000);
        
      },
      error: (err:any)=>{
        console.error("Error getting requests, line 43: ",err);
        this.service.openDialog("Error","Error al obtener las peticiones, intente mas tarde","../../../assets/images/error.png")
      }
    });

  }

  ordenarPorFecha(arr:any, ascendente = false) {
  const sortedArray = arr.slice(); // Copia el arreglo original para evitar modificarlo directamente

  sortedArray.sort((a:any, b:any) => {
    //console.log(a.date_created,b.date_created);
    const fechaA = new Date(a.date_created).getTime();
    const fechaB = new Date(b.date_created).getTime();

    if (ascendente) {
      return fechaA - fechaB; // Orden ascendente
    } else {
      return fechaB - fechaA; // Orden descendente
    }
  });

  console.log(sortedArray);

  return sortedArray;
  }

  ordenar(tipo:any){
    console.log("Ordenando tipo: ",tipo)
    if(tipo==1){
      this.peticiones = this.ordenarPorFecha(this.peticiones, false)
      this.orderAsc = false;
    }
    else{
      this.peticiones = this.ordenarPorFecha(this.peticiones, true);
      this.orderAsc = true;
    }
  }

  toggleMap(petition:any){
    if(petition.showMap){
      this.closeIndividualMap(petition);
    }
    else{
      this.showIndividualMap(petition);
    }
  }

  showIndividualMap(peticion:any){
    console.log("RECIBO: ",peticion);
    this.locationToSend = peticion.lat+","+peticion.lng;
    peticion.showMap = peticion.lat+","+peticion.lng;
    console.log("SETEAR: ",this.locationToSend);
  }

  closeIndividualMap(peticion:any){
    peticion.showMap = false;
  }

  getLocation(location: string) {
    this.lat = location.split(',')[0];
    this.lng = location.split(',')[1];
    console.log("RECIBIDO: ",this.lat,this.lng);
  }
  delete_petition(p:any){
    Swal.fire({
      title: "Continuar",
      text: "¿Desea continuar?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Guardar",
      denyButtonText: `Cancelar`
    }).then((result:any) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.entity_service.delete_petition({data: p.id}).subscribe({
          next: ()=>{
            this.entity_service.openDialog("¡Eliminado!","La petición fue eliminada correctamente","../../../assets/images/corazon_rojo.png");
            this.peticiones = this.peticiones.filter((p:any) => p.id != p.id);
          },
          error: (err:any) => {
            console.error("Error deleting petition", err);
            this.entity_service.openDialog("Error","Error al eliminar la petición","../../../assets/images/error.png");
          }
        });
      } else if (result.isDenied) {
        Swal.fire("Los cambios no fueron guardados", "", "info");
      }
    }); 
    
  }
}
