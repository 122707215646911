import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { RegisterSuccessComponent } from './components/register/register-success/register-success.component';
import { RegisterSuccessComponent as RSE } from './components/register-entity/register-success/register-success.component';
import { RegisterEntityComponent } from './components/register-entity/register-entity.component';
import { LoginEntityComponent } from './components/login-entity/login-entity.component';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './auth.guard';
import { LoginAdminComponent } from './components/login-admin/login-admin.component';
import { HomeComponent as HomeComponentAdmin } from './components/admin/home/home.component';
import { HomeComponent as HomeComponentEntity } from './components/entity/home/home.component';
import { UserActivationComponent } from './components/user-activation/user-activation.component';
import { NewEntitiesComponent } from './components/admin/new-entities/new-entities.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'inicio',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'perfil',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'informate',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'autoexamen',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'logros',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'historial',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'interes',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'configuracion',
  //   component: HomeComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'salir',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/institucion',
    component: LoginEntityComponent,
  },
  {
    path: 'login/admin',
    component: LoginAdminComponent,
  },
  {
    path: 'registrar',
    component: RegisterComponent
  },
  {
    path: 'activate/:token',
    component: UserActivationComponent
  },

  {
    path: 'registrar/institucion',
    component: RegisterEntityComponent
  },
  {
    path: 'bienvenido',
    component: RegisterSuccessComponent
  },
  {
    path: 'bienvenido/institucion',
    component: RSE
  },
  {
    path: 'password-recovery',
    component: PasswordRecoveryComponent
  },
  {
    path: 'portal',
    component: HomeComponentAdmin,
    canActivate: [AuthGuard]
  },
  {
    path: 'denuncias',
    component: HomeComponentAdmin,
    canActivate: [AuthGuard]
  },
  {
    path: 'tickets',
    component: HomeComponentAdmin,
    canActivate: [AuthGuard]
  },
  {
    path: 'nuevas-entidades',
    component: HomeComponentAdmin,
    canActivate: [AuthGuard]
  },
  {
    path: 'institucion/perfil',
    component: HomeComponentEntity,
    canActivate: [AuthGuard]
  },
  {
    path: 'institucion/tickets',
    component: HomeComponentEntity,
    canActivate: [AuthGuard]
  },
  {
    path: 'institucion/peticiones',
    component: HomeComponentEntity,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
