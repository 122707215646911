<div class="register-container">
    <div class="row justify-content-center" style="width: 100%;">
      <div class="col-10 col-md-9 form-filled">
        <div style="width: 100%;">
            <div class="row">
                <div class="col-12" style="display: flex; justify-content: center;">
                    <p style="font-size: 2.5vw; font-weight: bold; color: #003FB9;">Tickets</p>
                </div>
            </div>       
        </div>
        <ng-container *ngFor="let ticket of tickets">
            <div class="petition-card" [ngClass]="{'bkwhite': ticket.status == 1, 'bkgreen': ticket.status == 2, 'bkred': ticket.status == 3 }">
                <div class="row">
                    <div class="col-11">
                        <div class="row">
                            <span><strong>Descripción:</strong> {{ticket.text}}</span>
                        </div>
                        <div class="row">
                            <span><strong>Fecha de creación:</strong> {{ticket.date | date:'medium'}}</span>
                        </div>
                        <div class="row">
                            <span><strong>Status:</strong> {{ticket.status == 1? "Abierto" : "Resuelto"}}</span>
                        </div>
                        <div class="row" *ngIf="ticket.status != 1">
                            <span><strong>Comentario:</strong> {{ ticket.comment }}</span>
                        </div>
                    </div>
                    <div class="col-1" style="align-items: end; display: flex;" *ngIf="ticket.status == 1">
                        <i class="fa-solid fa-trash-can" style="cursor: pointer;" (click)="delete_ticket(ticket)"></i>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-12">
                        <span><strong>Status:</strong> {{ticket.status == 1? "Abierto" : "Resuelto"}}</span>
                    </div>
                </div>
                <div class="row" *ngIf="ticket.comment">
                    <div class="col-12">
                        <span><strong>Comentario:</strong> {{ ticket.comment }}</span>
                    </div>
                </div> -->
            </div>
            
        </ng-container>    
        <div class="col-12" style="display: flex; justify-content: center;" *ngIf="tickets.length == 0">
            <p style="font-size: 1.5vw; font-weight: bold;">No tienes ningun ticket abierto</p>
        </div>
      </div>
    </div>
</div>
