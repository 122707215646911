<div style="margin-bottom: 5%;">
    <form class="form-auto">
        <p style="font-size: 2.5vw; font-weight: bold; color: #003FB9; align-self: center;">Autoexamen</p>
        <p style="font-size: 1.2vw; font-weight: 100; color: #555555;">El siguiente examen busca identificar si existe algun requisito que el voluntario no cumpla o represente un impedimento para ser un potencial donante. Este examen es una guía para determinar si se es apto para donar y no busca reemplazar ningún paso del proceso de donación, al momento de la donación un examen similar será evaluado al donante.</p>
        <p *ngIf="last_update">Ultima actualización: {{last_update | date: "MMM dd, yyyy ':' hh:mm a"}}</p>
        <p *ngIf="!last_update">Ultima actualización: Nunca guardado</p>
        <hr>
        <div>
          <p>¿Tengo entre 18 y 60 años de edad?</p>
          <label class="form-label" style="padding: 10px;">
            <input class="form-check-input" type="radio" name="age" [value]=1 [(ngModel)]="age" st> Sí
          </label>
          <label class="form-label" style="padding: 10px;">
            <input class="form-check-input" type="radio" name="age" [value]=2 [(ngModel)]="age"> No
          </label>
        </div>
        
        <div>
          <p>¿Peso al menos 110 libras?</p>
          <label class="form-label" style="padding: 10px;">
            <input class="form-check-input" type="radio" name="weight" [value]=1 [(ngModel)]="weight"> Sí
          </label>
          <label class="form-label" style="padding: 10px;">
            <input class="form-check-input" type="radio" name="weight" [value]=2 [(ngModel)]="weight"> No
          </label>
        </div>
        
        <!-- Repite las siguientes preguntas para cada requisito -->
        
        <div>
          <p>¿He donado sangre en los últimos 4 meses (para mujeres) o 3 meses (para hombres)?</p>
          <label class="form-label" style="padding: 10px;">
            <input class="form-check-input" type="radio" name="donation" [value]=1 [(ngModel)]="donation"> Sí
          </label>
          <label class="form-label" style="padding: 10px;">
            <input class="form-check-input" type="radio" name="donation" [value]=2 [(ngModel)]="donation"> No
          </label>
        </div>
        
        <div>
          <p>¿Padezco problemas de presión arterial, afecciones cardíacas, asma o diabetes?</p>
          <label class="form-label" style="padding: 10px;">
            <input class="form-check-input" type="radio" name="health" [value]=1 [(ngModel)]="health"> Sí
          </label>
          <label class="form-label" style="padding: 10px;">
            <input class="form-check-input" type="radio" name="health" [value]=2 [(ngModel)]="health"> No
          </label>
        </div>
        
        <div>
          <p>¿Padezco una enfermedad infecciosa en este momento ni la he padecido en el último mes?</p>
          <label class="form-label" style="padding: 10px;">
            <input class="form-check-input" type="radio" name="infection" [value]=1 [(ngModel)]="infection"> Sí
          </label>
          <label class="form-label" style="padding: 10px;">
            <input class="form-check-input" type="radio" name="infection" [value]=2 [(ngModel)]="infection"> No
          </label>
        </div>
        
        <div>
          <p>¿Me he sometido a una intervención quirúrgica en los últimos 6 meses?</p>
          <label class="form-label" style="padding: 10px;">
            <input class="form-check-input" type="radio" name="surgery" [value]=1 [(ngModel)]="surgery"> Sí
          </label>
          <label class="form-label" style="padding: 10px;">
            <input class="form-check-input" type="radio" name="surgery" [value]=2 [(ngModel)]="surgery"> No
          </label>
        </div>
        
        <div>
          <p>¿He padecido hepatitis después de los 12 años?</p>
          <label class="form-label" style="padding: 10px;">
            <input class="form-check-input" type="radio" name="hepatitis" [value]=1 [(ngModel)]="hepatitis"> Sí
          </label>
          <label class="form-label" style="padding: 10px;">
            <input class="form-check-input" type="radio" name="hepatitis"[value]=2 [(ngModel)]="hepatitis"> No
        </label>
    </div>
    <div>
        <p>¿Estoy embarazada en este momento ni he estado embarazada en los últimos 6 meses?</p>
        <label class="form-label" style="padding: 10px;">
        <input class="form-check-input" type="radio" name="pregnancy" [value]=1 [(ngModel)]="pregnancy"> Sí
        </label>
        <label class="form-label" style="padding: 10px;">
        <input class="form-check-input" type="radio" name="pregnancy" [value]=2 [(ngModel)]="pregnancy"> No
        </label>
    </div>
    <div>
        <p>¿Estoy dando de mamar en la actualidad?</p>
        <label class="form-label" style="padding: 10px;">
        <input class="form-check-input" type="radio" name="breastfeeding" [value]=1 [(ngModel)]="breastfeeding"> Sí
        </label>
        <label class="form-label" style="padding: 10px;">
        <input class="form-check-input" type="radio" name="breastfeeding" [value]=2 [(ngModel)]="breastfeeding"> No
        </label>
    </div>
    <div>
        <p>¿He ingerido alcohol en las últimas 24 horas antes de la donación?</p>
        <label class="form-label" style="padding: 10px;">
        <input class="form-check-input" type="radio" name="alcohol" [value]=1 [(ngModel)]="alcohol"> Sí
        </label>
        <label class="form-label" style="padding: 10px;">
        <input class="form-check-input" type="radio" name="alcohol" [value]=2 [(ngModel)]="alcohol"> No
        </label>
    </div>
    
    <button type="submit" (click)="onSubmit()" style="color: #FFFFFF; background-color: #F02D3A; border-color: #F02D3A; box-shadow: none; border-width: 0px; min-width: 2rem; padding: 10px 15px; align-self: center;">Enviar</button>

    </form>
</div>
