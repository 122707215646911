import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-register-entity',
  templateUrl: './register-entity.component.html',
  styleUrls: ['./register-entity.component.css']
})
export class RegisterEntityComponent {

  registerForm: FormGroup;
  departaments: any;
  lat: any;
  lng: any;

  emailExists:boolean;
  passwordInequal:boolean;
  wrongPassword:boolean;
  messages:Message[];

  constructor(private formBuilder: FormBuilder, private userService:UsersService, private router:Router) { 
    this.departaments = [];
    this.emailExists = false;
    this.lat = null;
    this.lng = null;
  }

  ngOnInit(){
    this.registerForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      direccion: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      departament: ['', Validators.required],
      telefono: ['',Validators.nullValidator]
    });

    this.userService.get_departaments().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        this.departaments = response.data;
      },
      error: (err:any)=>{
        console.error("Error getting departaments, line 43: ",err);
      }
    });

    this.messages = [{ severity: 'success', summary: 'Success', detail: 'Message Content' }]
  }

  onSubmit() {
    this.resetFlags();
    this.validateEmail();
    if(this.emailExists || this.wrongPassword || this.passwordInequal || !this.lat || !this.lng){
      return;
    }
    if (this.registerForm.valid) {
      let data = {
        name: this.registerForm.controls['nombre'].value,
        address: this.registerForm.controls['direccion'].value,
        depto: this.registerForm.controls['departament'].value,
        email: this.registerForm.controls['email'].value,
        phone: this.registerForm.controls['telefono'].value,
        lat: this.lat,
        lng: this.lng
      }
      console.log("DATA ANTES DE ENVIAR:",data);
      this.userService.new_entity(data).subscribe({
        next: (response:any)=>{
          console.log("ESTATUSS ",response)
          if(response.status){ 
            this.router.navigate(['/bienvenido/institucion']);
          }
        },
        error: (err:any)=>{
          console.error("Error on create new user, line 68: ",err);
        }
      });
    } 
    else {
      console.log('Formulario inválido',this);
      
    }
  }

  validateEmail(){
    console.log("validate email init")
    if(this.registerForm.controls['email'].value){
      this.userService.get_email_entity(this.registerForm.controls['email'].value).subscribe({
        next: (response:any)=>{
          console.log(response);
        },
        error: (err:any)=>{
          if(err.status == 403){
            this.emailExists = true;
          }
          else{
            console.error("Error gettin email, line 78: ",err);
          }
        }
      });
    }
  }
  resetFlags(){
    this.emailExists = false;
    this.passwordInequal = false;
    this.wrongPassword = false;
  }
  navigateHome(){
    this.router.navigate(['/'])
  }
  getLocation(location: string) {
    this.lat = location.split(',')[0];
    this.lng = location.split(',')[1];
    console.log("RECIBIDO: ",this.lat,this.lng);
  }
}
