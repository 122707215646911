import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.css']
})
export class ComplaintsComponent {

  peticiones:any;
  orderAsc:boolean;
  locationToSend:any;
  lat:any;
  lng:any;

  constructor(private admin_service:AdminService, private router:Router){}

  ngOnInit(){
    console.log("iniciando ngOnInit")
    this.admin_service.get_complaints().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ", response);
        this.peticiones = response.data.map((element:any)=>{
          //console.log(element);
          element.request.date_created = element.request.date_created.split('T')[0];
          if(element.request.date_start)element.request.date_start = element.request.date_start.replace('T',' ').split('.')[0];
          if(element.request.date_end)element.request.date_end = element.request.date_end.replace('T',' ').split('.')[0];
          if(element.request.blood_type)element.request.blood_type = element.request.blood_type.replaceAll(",",", ");
          element.request.showMap = "";
          return element;
        });

        console.log("Quedaron como:", this.peticiones);
      },
      error: (err:any) => {
        console.error("Error getting complaints, line ", err);
        if(err.status == 403){
          //this.router.navigate(['/login/admin'])
        }
      }
    });
  }
  ordenarPorFecha(arr:any, ascendente = false) {
    const sortedArray = arr.slice(); // Copia el arreglo original para evitar modificarlo directamente
  
    sortedArray.sort((a:any, b:any) => {
      //console.log(a.date_created,b.date_created);
      const fechaA = new Date(a.date_created).getTime();
      const fechaB = new Date(b.date_created).getTime();
  
      if (ascendente) {
        return fechaA - fechaB; // Orden ascendente
      } else {
        return fechaB - fechaA; // Orden descendente
      }
    });
  
    console.log(sortedArray);
  
    return sortedArray;
    }
  
    ordenar(tipo:any){
      console.log("Ordenando tipo: ",tipo)
      if(tipo==1){
        this.peticiones = this.ordenarPorFecha(this.peticiones, false)
        this.orderAsc = false;
      }
      else{
        this.peticiones = this.ordenarPorFecha(this.peticiones, true);
        this.orderAsc = true;
      }
    }
  
    toggleMap(petition:any){
      if(petition.request.showMap){
        this.closeIndividualMap(petition);
      }
      else{
        this.showIndividualMap(petition);
      }
    }
  
    showIndividualMap(peticion:any){
      console.log("RECIBO: ",peticion);
      this.locationToSend = peticion.request.lat+","+peticion.request.lng;
      peticion.request.showMap = peticion.request.lat+","+peticion.request.lng;
      console.log("SETEAR: ",this.locationToSend);
    }
  
    closeIndividualMap(peticion:any){
      peticion.request.showMap = false;
    }

    accept_complaint(petition:any){
      console.log(petition);
      Swal.fire({
        title: "Continuar",
        text: "¿Desea continuar?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Guardar",
        denyButtonText: `Cancelar`
      }).then((result:any) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.admin_service.update_complaint({status: 2, id:petition}).subscribe({
            next: (response: any) => {
              console.log("respuesta: ",response);
              this.admin_service.openDialog("Aceptada","Las denuncias fueron aceptadas, la publicación será eliminada.","../../../assets/images/corazon_rojo.png");
              this.ngOnInit();
            },
            error: (err:any) => {
              console.error("Error updating complaint, line 103",err);
              this.admin_service.openDialog("Error","Error al actualizar la denuncia","../../../assets/images/error.png");
            }
          });
        } else if (result.isDenied) {
          Swal.fire("Los cambios no fueron guardados", "", "info");
        }
      });
      
    }
    reject_complaint(petition:any){
      console.log(petition);
      Swal.fire({
        title: "Continuar",
        text: "¿Desea continuar?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Guardar",
        denyButtonText: `Cancelar`
      }).then((result:any) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.admin_service.update_complaint({status: 3, id:petition}).subscribe({
            next: (response: any) => {
              console.log("respuesta: ",response);
              this.admin_service.openDialog("Rechazada","Las denuncias fueron rechazadas.","../../../assets/images/corazon_rojo.png");
              this.ngOnInit();
            },
            error: (err:any) => {
              console.error("Error updating complaint, line 103",err);
              this.admin_service.openDialog("Error","Error al actualizar la denuncia","../../../assets/images/error.png");
            }
          });
        } else if (result.isDenied) {
          Swal.fire("Los cambios no fueron guardados", "", "info");
        }
      });
    }
}
