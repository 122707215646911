import { Component } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';

export interface Logro {
  id: number;
  name: string;
  description: string;
  complete_in: number;
  actual: number;
  img: string;
}

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.css']
})
export class AchievementsComponent {

  constructor(private service:UsersService){

  }

  logros: Logro[] = [
    { id: 1, name: 'La primera de muchas', description: "Realiza tu primera donación, registra una donación realizada.", complete_in: 1, actual: 0, img: 'assets/images/corazon_rojo.png' },
    { id: 2, name: 'En racha', description: "Registra 3 donaciones realizadas, no te confíes hablamos de donar mas de un año.", complete_in: 5, actual: 0, img: 'assets/images/corazon_rojo.png' },
    { id: 3, name: 'Pilar de la comunidad', description: "Contribuye a la comunidad y realiza una cantidad absurda de donaciones.", complete_in: 10, actual: 0, img: 'assets/images/corazon_rojo.png' },
    { id: 4, name: 'Protector', description: "Realiza 5 denuncias que sean aprobadas por los moderadores.", complete_in: 5, actual: 0, img: 'assets/images/corazon_rojo.png' },
    { id: 5, name: 'Al día', description: "Completa toda la información de tu perfil.", complete_in: 1, actual: 0, img: 'assets/images/corazon_rojo.png' },
    { id: 6, name: 'Al pendiente', description: "Registra alguna petición a tu lista de interés.", complete_in: 1, actual: 0, img: 'assets/images/corazon_rojo.png'},
    
  ];

  ngOnInit(){
    console.log("Entro");
    this.service.get_user_achievements().subscribe({
      next: (response:any)=>{
        console.log("respuesta: ",response);
        this.logros = this.logros.map(l =>{
          //primera donación
          
          if(l.id == 1){
            l.actual = response.data.blood_requests[0][0].total > 1? 1 : response.data.blood_requests[0][0].total;
          }
          else if(l.id == 2){
            l.actual = response.data.blood_requests_all[0][0].total > 3? 3 : response.data.blood_requests_all[0][0].total;
          }
          else if(l.id == 3){
            l.actual = response.data.blood_requests_all[0][0].total > 10? 10 : response.data.blood_requests_all[0][0].total;
          }
          else if(l.id == 4){
            l.actual = response.data.complaints[0][0].total > 5? 5 : response.data.complaints[0][0].total;
          }
          else if(l.id == 5){
            let complete = true;
            let profile_info = response.data.profile_info[0][0];

            const fieldsToCheck = [
              profile_info.first_name,
              profile_info.last_name,
              profile_info.phone,
              profile_info.birthday,
              profile_info.weight,
              profile_info.blood_type,
              profile_info.origin_depto,
              profile_info.lat,
              profile_info.lng
            ];
          
            for (let value of fieldsToCheck) {
              if (value === undefined || value === null) {
                complete = false;
                break;
              }
            }
            complete ? l.actual = 1 : l.actual = 0;
          }
          else if(l.id == 6){
            l.actual = response.data.interest_list[0][0].total > 1? 1 : response.data.interest_list[0][0].total;
          }
          else{
            l.actual = 0;
          }

          return l;
        });
        console.log("quedo como: ", this.logros)
      },
      error: (err:any) => {
        console.error("Error getting achievements of user, on line 41",err);
      }
    });
  }
}
