<div style="display: flex; margin-bottom: 15px;">
    <div class="row" style="justify-content: space-between; width: 100%;">
        <div class="col-5">
            <span *ngIf="orderAsc" (click)="ordenar(1)" style="cursor: pointer;"><i class="fa-solid fa-arrow-up" (click)="ordenar(1)"></i> Mas antiguos primero</span>
            <span *ngIf="!orderAsc" (click)="ordenar(2)" style="cursor: pointer;"><i class="fa-solid fa-arrow-down" (click)="ordenar(2)"></i>  Mas recientes primero</span>  
        </div>
        <div class="col-5" style="display: flex; justify-content: flex-end;">
            <button class="filter-button" data-bs-toggle="modal" data-bs-target="#filter-modal">Filtros</button>
        </div>
    </div>
</div>

<div style="display: flex; gap: 10px; align-items: center; margin: 20px;">
    <div style="background-color: #F02D3A; width: 1rem; height: 1rem; border-radius: 6px;"></div>
    <span>Peticiones</span>
    <div style="background-color: #9197AE; width: 1rem; height: 1rem; border-radius: 6px;"></div>
    <span>Jornadas/Peticiones de institución</span>
</div>

<ng-container *ngFor="let p of peticiones">
    <div class="petition-card" *ngIf="p.type == 1">
        <div style="width: 3%; height: auto; background-color: #9197AE;" *ngIf="p.entity_id"></div>
        <div style="width: 3%; height: auto; background-color: #F02D3A;" *ngIf="!p.entity_id"></div>
        <div [ngClass]="{'type-one-background': !p.entity_id, 'type-two-background': p.entity_id}">
            <div style="display: flex; justify-content: space-between;">
                <p><b>{{p.title}}</b></p>
                <div class="dropdown">
                    <button *ngIf="!p.saved_in_interest" type="button" (click)="add_petition_to_interested(p)" aria-expanded="false" style="background: none; border: none;">
                        <i class="fa-regular fa-star" matTooltip="Me interesa" style="cursor: pointer;"></i>
                    </button>
                    <button *ngIf="p.saved_in_interest" type="button" aria-expanded="false" style="background: none; border: none;">
                        <i class="fa-solid fa-star" matTooltip="¡Añadido a eventos de interes!" style="cursor: pointer;"></i>
                    </button>
                    <button *ngIf="!p.saved_in_history" type="button" data-bs-toggle="modal" data-bs-target="#date-modal" (click)="set_petition(p)" aria-expanded="false" style="background: none; border: none;">
                        <i class="fa-solid fa-plus" matTooltip="Añadir al historial" style="cursor: pointer;"></i>
                    </button>
                    <button *ngIf="p.saved_in_history" type="button" aria-expanded="false" style="background: none; border: none;">
                        <i class="fa-solid fa-check" matTooltip="¡Añadido al historial!" style="cursor: pointer;"></i>
                    </button>
                </div>
            </div>
            <p>{{p.description}}</p>
            <hr>
            <div style="display: flex; justify-content: space-between;">    
                <div style="display: flex; gap: 35px; justify-content: flex-start; align-items: baseline;">
                    <span><i class="fa-solid fa-droplet"  matTooltip="Tipo de sangre requerido"></i>{{p.blood_type}}</span>
                    <span><i class="fa-regular fa-calendar" matTooltip="Fecha creación"></i>{{p.date_created}}</span>
                    <span><i class="fa-solid fa-phone"  matTooltip="Contacto"></i>{{p.phone}}</span> 
                    <span><i [ngClass]="{'icon-blue': p.showMap, 'icon-black': !p.showMap}" class="fa-solid fa-location-dot" matTooltip="Ver ubicación" style="cursor: pointer;" (click)="toggleMap(p)"></i>{{p.entity}}</span>
                </div>
                <div>
                    <button *ngIf="!p.already_reported" data-bs-toggle="modal" data-bs-target="#complaint-modal" style="background: none; border: none;" (click)="set_complaint(p)">
                        <i  class="fa-solid fa-triangle-exclamation" matTooltip="Denunciar" style="align-self: flex-end; cursor: pointer;"></i>
                    </button>
                    <button *ngIf="p.already_reported" style="background: none; border: none;" >
                        <i class="fa-solid fa-flag" matTooltip="Denunciaste esta publicación" style="align-self: flex-end; cursor: pointer;"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 100%; background-color: mintcream;" 
         *ngIf="p.type == 1" [ngClass]="{'mapVisible': p.showMap, 'mapHidden' : !p.showMap }">
        <app-map [locationSended_auto]="p.showMap" [index]="p.id"></app-map>
    </div>
    
    <div class="petition-card" *ngIf="p.type == 2">
        <div style="width: 3%; height: auto; background-color: #9197AE;"></div>
        <div style="width: 97%; padding: 15px; background-color: #F1F1F1;">
            <div style="display: flex; justify-content: space-between;">
                <p><b>{{p.title}}</b></p>
                <!-- <i class="fa-solid fa-plus" matTooltip="Registrar" style="cursor: pointer;"></i> -->
                <div class="dropdown">
                    <button *ngIf="!p.saved_in_interest" type="button" (click)="add_petition_to_interested(p)" aria-expanded="false" style="background: none; border: none;">
                        <i class="fa-regular fa-star" matTooltip="Me interesa" style="cursor: pointer;"></i>
                    </button>
                    <button *ngIf="p.saved_in_interest" type="button" aria-expanded="false" style="background: none; border: none;">
                        <i class="fa-solid fa-star" matTooltip="¡Añadido a eventos de interes!" style="cursor: pointer;"></i>
                    </button>
                    <button *ngIf="!p.saved_in_history" type="button" data-bs-toggle="modal" data-bs-target="#date-modal" (click)="set_petition(p)" aria-expanded="false" style="background: none; border: none;">
                        <i class="fa-solid fa-plus" matTooltip="Añadir al historial" style="cursor: pointer;"></i>
                    </button>
                    <button *ngIf="p.saved_in_history" type="button" aria-expanded="false" style="background: none; border: none;">
                        <i class="fa-solid fa-check" matTooltip="¡Añadido al historial!" style="cursor: pointer;"></i>
                    </button>
                </div>
            </div>
            <p>{{p.description}}</p>
            <span><i class="fa-regular fa-clock" matTooltip="Hora jornada"></i>{{p.date_start | date: 'medium'}}-{{p.date_end | date: 'medium'}}</span>
            <hr>
            <div style="display: flex; justify-content: space-between;">
                <div style="display: flex; gap: 35px; justify-content: flex-start; align-items: baseline;">
                    
                    <span><i class="fa-regular fa-calendar" matTooltip="Fecha creación"></i>{{p.date_created}}</span>
                    <span><i class="fa-solid fa-phone"></i>{{p.phone}}</span>
                    <span><i [ngClass]="{'icon-blue': p.showMap, 'icon-black': !p.showMap}" class="fa-solid fa-location-dot" matTooltip="Ver ubicación" style="cursor: pointer;" (click)="toggleMap(p)"></i>{{p.entity}}</span>
            
                </div>
                <div>
                    <button *ngIf="!p.already_reported" data-bs-toggle="modal" data-bs-target="#complaint-modal" style="background: none; border: none;" (click)="set_complaint(p)">
                        <i  class="fa-solid fa-triangle-exclamation" matTooltip="Denunciar" style="align-self: flex-end; cursor: pointer;"></i>
                    </button>
                    <button *ngIf="p.already_reported" style="background: none; border: none;" >
                        <i class="fa-solid fa-flag" matTooltip="Denunciaste esta publicación" style="align-self: flex-end; cursor: pointer;"></i>
                    </button>
                </div>
            </div>
        </div> 
    </div>
    <div style="width: 100%; background-color: mintcream;" 
         *ngIf="p.type == 2" [ngClass]="{'mapVisible': p.showMap, 'mapHidden' : !p.showMap }">
        <app-map [locationSended_auto]="p.showMap" [index]="p.id"></app-map>
    </div>
</ng-container>

<!-- Filters modal -->
<div class="modal fade custom-fade" id="filter-modal" tabindex="-1" aria-labelledby="filters" aria-hidden="true">
    <!-- <div class="modal-dialog  modal-dialog-centered" style="max-width: 75vw;"> -->
    <div class="modal-dialog  modal-dialog-centered" style="max-width: 75vw;">
      <div class="modal-content" >
        <div class="modal-header" style="border-bottom: none;">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="padding-left: 60px; padding-right: 60px;">
            <h1>Filtros</h1>
            <div class="row" style="padding: 15px 0px 15px 0px;">
                <div class="col-12 col-md-6">
                    <h4>Tipos de sangre</h4>   
                    <div style="display: flex; gap: 10px; flex-direction: column;">
                        <div class="row">
                            <div class="col-12" style="align-items: center; display: flex; gap: 10px;">
                                <input type="checkbox" id="tipo_sangre_o" name="tipo_sangre[]" [checked]="tiposSangreSeleccionados.includes('O+')" 
                                    value="tipo_sangre_o" (change)="toggleTipoSangre('O+')" class="custom-checkbox">
                                <label for="tipo_sangre_o" style="width: 2vw;"><strong>O+</strong></label>

                                <input type="checkbox" id="tipo_sangre_a" name="tipo_sangre[]" [checked]="tiposSangreSeleccionados.includes('A+')" 
                                    value="tipo_sangre_a" (change)="toggleTipoSangre('A+')" class="custom-checkbox">
                                <label for="tipo_sangre_a" style="width: 2vw;"><strong>A+</strong></label>

                                <input type="checkbox" id="tipo_sangre_b" name="tipo_sangre[]" [checked]="tiposSangreSeleccionados.includes('B+')" 
                                    value="tipo_sangre_b" (change)="toggleTipoSangre('B+')" class="custom-checkbox">
                                <label for="tipo_sangre_b" style="width: 2vw;"><strong>B+</strong></label>

                                <input type="checkbox" id="tipo_sangre_ab" name="tipo_sangre[]" [checked]="tiposSangreSeleccionados.includes('AB+')" 
                                    value="tipo_sangre_ab" (change)="toggleTipoSangre('AB+')" class="custom-checkbox">
                                <label for="tipo_sangre_ab" style="width: 2vw;"><strong>AB+</strong></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" style="align-items: center; display: flex; gap: 10px;">
                                <input type="checkbox" id="tipo_sangre_o_" name="tipo_sangre[]" [checked]="tiposSangreSeleccionados.includes('O-')" 
                                    value="tipo_sangre_o_" (change)="toggleTipoSangre('O-')" class="custom-checkbox">
                                <label for="tipo_sangre_o_" style="width: 2vw;"><strong>O- </strong></label>

                                <input type="checkbox" id="tipo_sangre_a_" name="tipo_sangre[]" [checked]="tiposSangreSeleccionados.includes('A-')" 
                                    value="tipo_sangre_a_" (change)="toggleTipoSangre('A-')" class="custom-checkbox">
                                <label for="tipo_sangre_a_" style="width: 2vw;"><strong>A- </strong></label>

                                <input type="checkbox" id="tipo_sangre_b_" name="tipo_sangre[]" [checked]="tiposSangreSeleccionados.includes('B-')" 
                                    value="tipo_sangre_b_" (change)="toggleTipoSangre('B-')" class="custom-checkbox">
                                <label for="tipo_sangre_b_" style="width: 2vw;"><strong>B- </strong></label>

                                <input type="checkbox" id="tipo_sangre_ab_" name="tipo_sangre[]" [checked]="tiposSangreSeleccionados.includes('AB-')" 
                                    value="tipo_sangre_ab_" (change)="toggleTipoSangre('AB-')" class="custom-checkbox">
                                <label for="tipo_sangre_ab_" style="width: 2vw;"><strong>AB- </strong></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-12 col-md-6">
                    <h4>Ubicación</h4>  
                    <input id="ubicacion" placeholder="Kilómetros a la redonda" min="0" class="custom-inputs-filter" [(ngModel)]="km_filter" type="number" min="0">
                    
                </div>
            </div>
            <div class="row" style="min-height: 300px;">
                <app-map (locationEvent)="getLocation($event)" [locationSended]="locationToSend" [index]="-2" [distance]="km_filter"></app-map>
            </div>
            <div class="row" style="padding: 15px 0px 15px 0px;">
                <div class="col-12 col-md-6" *ngIf="departaments">
                    <h4>Por departamento</h4> 
                    <select class="custom-inputs-filter" [(ngModel)]="depto_filter" [ngClass]="{'color-light': !depto_filter}">
                        <option value="" selected hidden>Departamento</option>
                        <option *ngFor="let depto of departaments" [ngValue]="depto.id">{{depto.name}}</option>
                    </select>
                </div>
                <div class="col-12 col-md-6">
                    <h4>Fecha de creación</h4>
                    <div style="display: flex;">
                        <div style="width: 50%;">
                            <!-- <h6>Desde</h6> -->
                            <input id="startDate" onfocus="(this.type='date')" onblur="(this.type='text')" class="custom-inputs-filter" type="text" placeholder="Desde" [(ngModel)]="date_start"/>
                        </div>
                        <div style="width: 50%;">
                            <!-- <h6>Hasta</h6> -->
                            <input id="endDate" onfocus="(this.type='date')" onblur="(this.type='text')" class="custom-inputs-filter" type="text" placeholder="Hasta" [(ngModel)]="date_end"/>
                        </div>
                    </div>
                </div>
            </div>          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="clean_filter()">Limpiar</button>
          <button type="button" class="btn btn-primary" (click)="filter()" data-bs-dismiss="modal">Filtrar</button>
          <button type="button" class="btn btn-primary" matTooltip="Fitramos todas las peticiones a las que eres compatible" (click)="filter_()" data-bs-dismiss="modal">Filtrar por compatibilidad</button>
        </div>
      </div>
    </div>
</div>

<!-- complaint modal -->
<div class="modal fade custom-fade" id="complaint-modal" tabindex="-1" aria-labelledby="filters" aria-hidden="true" >
    <div class="modal-dialog  modal-dialog-centered" style="max-width: 45vw;">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: none;">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="padding-left: 10%; padding-right: 10%;">
            <h2>Denuncia</h2>
            <textarea class="form-control" aria-label="complaint detail" [(ngModel)]="complaint_detail"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="send_complaint()" data-bs-dismiss="modal">Enviar</button>
        </div>
      </div>
    </div>
</div>

<!-- Event date modal -->
<div class="modal fade custom-fade" id="date-modal" tabindex="-1" aria-labelledby="filters" aria-hidden="true" >
    <div class="modal-dialog  modal-dialog-centered" style="max-width: 45vw;">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: none;">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="padding-left: 60px;">
            <h1>¿Cuándo fue el día que donaste?</h1>
            <input class="form-control" type="date" aria-label="event date input" [(ngModel)]="event_date_history">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="add_petition_to_history()" data-bs-dismiss="modal">Registrar</button>
        </div>
      </div>
    </div>
</div>