<p style="font-size: 2.5vw; font-weight: bold; color: #003FB9; align-self: center;">Historial</p>
<div style="display: flex; margin-bottom: 15px;">
    <div class="row" style="justify-content: space-between; width: 100%; align-items: center;">
        <div class="col-5">
            <span *ngIf="orderAsc" (click)="ordenar(1)" style="cursor: pointer;"><i class="fa-solid fa-arrow-up" (click)="ordenar(1)"></i> Mas antiguos primero</span>
            <span *ngIf="!orderAsc" (click)="ordenar(2)" style="cursor: pointer;"><i class="fa-solid fa-arrow-down" (click)="ordenar(2)"></i>  Mas recientes primero</span>  
        </div>
        <div class="col-7" style="display: flex; justify-content: flex-end;">
            <button  data-bs-toggle="modal" data-bs-target="#external-modal" class="external-button"><i class="fa-regular fa-pen-to-square"></i> Registrar donación externa</button>
        </div>
    </div>
</div>
<div style="display: flex; gap: 10px; align-items: center; margin: 20px;">
    <div style="background-color: #F02D3A; width: 1rem; height: 1rem; border-radius: 6px;"></div>
    <span>Registros de red roja</span>
    <div style="background-color: #273043; width: 1rem; height: 1rem; border-radius: 6px;"></div>
    <span>Registros externos</span>
    <div style="background-color: #9197AE; width: 1rem; height: 1rem; border-radius: 6px;"></div>
    <span>Registros de institución</span>
</div>
<ng-container *ngFor="let p of peticiones">
    <div class="petition-card" *ngIf="p.type == 1">
        <div style="width: 3%; height: auto; background-color: #F02D3A;"></div>
        <div style="width: 97%; padding: 15px; background-color: snow;">
            <div style="display: flex; justify-content: space-between;">
                <p><b>{{p.title}}</b></p>
                <div class="dropdown" *ngIf="!p.saved_in_history">
                    <button type="button" (click)="delete_from_history(p)" aria-expanded="false" style="background: none; border: none;">
                        <i class="fa-solid fa-xmark" matTooltip="Registrar" style="cursor: pointer;"></i>
                    </button>
                </div>
            </div>
            <p>{{p.description}}</p>
            <div style="display: flex; justify-content: space-between;">    
                <div style="display: flex; gap: 35px; justify-content: flex-start; align-items: baseline;">
                    <span><i class="fa-regular fa-calendar" matTooltip="Fecha de donación"></i>{{p.date_event}}</span>
                </div>
            </div>
        </div>
    </div>
    
    <div class="petition-card" *ngIf="p.type == 2">
        <div style="width: 3%; height: auto; background-color: #9197AE;"></div>
        <div style="width: 97%; padding: 15px; background-color: #F1F1F1;">
            <div style="display: flex; justify-content: space-between;">
                <p><b>{{p.title}}</b></p>
                <div class="dropdown">
                    <button type="button" (click)="delete_from_history(p)" aria-expanded="false" style="background: none; border: none;">
                        <i class="fa-solid fa-xmark" matTooltip="Registrar" style="cursor: pointer;"></i>
                    </button>
                </div>
            </div>
            <p>{{p.description}}</p>
            <div style="display: flex; justify-content: space-between;">
                <div style="display: flex; gap: 35px; justify-content: flex-start; align-items: baseline;">
                    <span><i class="fa-regular fa-calendar" matTooltip="Fecha de donación"></i>{{p.date_event}}</span>
                </div>
            </div>
        </div> 
    </div>
    <div class="petition-card" *ngIf="p.type == 3">
        <div style="width: 3%; height: auto; background-color: #273043;"></div>
        <div style="width: 97%; padding: 15px; background-color: #EDF1FF;">
            <div style="display: flex; justify-content: space-between;">
                <p><b>{{p.title}}</b></p>
                <div class="dropdown" *ngIf="!p.saved_in_history">
                    <button type="button" (click)="delete_from_history(p)" aria-expanded="false" style="background: none; border: none;">
                        <i class="fa-solid fa-xmark" matTooltip="Registrar" style="cursor: pointer;"></i>
                    </button>
                </div>
            </div>
            <p>{{p.description}}</p>
            <div style="display: flex; justify-content: space-between;">    
                <div style="display: flex; gap: 35px; justify-content: flex-start; align-items: baseline;">
                    <span><i class="fa-regular fa-calendar" matTooltip="Fecha de donación"></i>{{p.date_event}}</span>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div class="modal fade custom-fade" id="external-modal" tabindex="-1" aria-labelledby="filters" aria-hidden="true">
    <!-- <div class="modal-dialog  modal-dialog-centered" style="max-width: 75vw;"> -->
    <div class="modal-dialog  modal-dialog-centered" style="max-width: 75vw;">
      <div class="modal-content" >
        <div class="modal-header" style="border-bottom: none;">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="padding-left: 60px; padding-right: 60px; display: flex; flex-direction: column;">
            <div class="row" style="padding: 15px 0px 15px 0px; display: flex; justify-content: center;">
                <h1 style="width: auto;">Registrar donación</h1>
            </div>
            <div class="row" style="padding: 15px 0px 15px 0px;">
                <div class="form-group col-12">
                    <!-- <h4>Título</h4>   -->
                    <input id="ubicacion" placeholder="Título" class="custom-inputs-filter" [(ngModel)]="external_title" type="text" min="0">
                </div>
            </div>
            <div class="row" style="padding: 15px 0px 15px 0px;">
                <div class="form-group col-12">
                    <!-- <h4>Descripción</h4>   -->
                    <input id="ubicacion" placeholder="Descripción" class="custom-inputs-filter" [(ngModel)]="external_description" type="text" min="0">
                </div>
            </div>
            <div class="row" style="padding: 15px 0px 15px 0px;">
                <div class="col-12">
                    <!-- <h4>Fecha de creación</h4> -->
                    <div style="display: flex;">
                        <div style="width: 100%;">
                            <!-- <h6>Hasta</h6> -->
                            <input id="endDate" onfocus="(this.type='date')" onblur="(this.type='text')" class="custom-inputs-filter" type="text" placeholder="Fecha" [(ngModel)]="external_date"/>
                        </div>
                    </div>
                </div>
            </div>         
        </div>
        <div class="modal-footer" style="justify-content: center;">
          <button type="button" class="btn btn-primary" (click)="register_external()" data-bs-dismiss="modal" [disabled]="!external_title || !external_date || !external_description">Registrar</button>
        </div>
      </div>
    </div>
</div>