<!-- <div>
    <img src="../../../assets/images/Logo.png" style="height: 50%">
    <
</div> -->
<div class="row" style="height: 100vh;">
    <div class="col-4" style="padding: 3%; border-right: solid 1px #ebebeb; position: fixed; height: 100%;">
        <div class="row" style="margin-left: 30%;">
            <div class="row">
                <img src="../../../assets/images/Logo.png" style="width: 10vw; margin-bottom: 25px;">
            </div>
            <div class="row" class="hover-icon">
                <p style="cursor: pointer; font-size: 1.2rem;" (click)="CambiarVista(1)"><i class="fa-solid fa-house"></i>Inicio</p>
            </div>
            <div class="row" class="hover-icon">
                <p style="cursor: pointer; font-size: 1.2rem;" (click)="CambiarVista(2)"><i class="fa-regular fa-user"></i>Perfil</p>
            </div>
            <div class="row" class="hover-icon">
                <p style="cursor: pointer; font-size: 1.2rem;" (click)="CambiarVista(3)"><i class="fa-solid fa-circle-info"></i><a>Infórmate</a></p>
            </div>
            <div class="row" class="hover-icon">
                <p style="cursor: pointer; font-size: 1.2rem;" (click)="CambiarVista(4)"><i class="fa-regular fa-pen-to-square"></i><a>Autoexámen</a></p>
            </div>
            <div class="row" class="hover-icon">
                <p style="cursor: pointer; font-size: 1.2rem;" (click)="CambiarVista(5)"><i class="fa-solid fa-trophy"></i><a>Logros</a></p>
            </div>
            <div class="row" class="hover-icon">
                <p style="cursor: pointer; font-size: 1.2rem;" (click)="CambiarVista(6)"><i class="fa-regular fa-clock"></i><a>Historial</a></p>
            </div>
            <div class="row" class="hover-icon">
                <p style="cursor: pointer; font-size: 1.2rem;" (click)="CambiarVista(9)"><i class="fa-solid fa-star"></i><a>Eventos de interes</a></p>
            </div>
            <!-- <div class="row" class="hover-icon">
                <p style="cursor: pointer; font-size: 1.2rem;" (click)="CambiarVista(7)"><i class="fa-solid fa-gear"></i><a>Configuración</a></p>
            </div> -->
            <div class="row" class="hover-icon">
                <p style="cursor: pointer; font-size: 1.2rem;" (click)="cerrarSesion()"><i class="fa-solid fa-arrow-right-from-bracket"></i><a>Cerrar Sesión</a></p>
            </div>
        </div>
        <div class="row" style="justify-content: center;">
            <button class="btn-create" (click)="CambiarVista(8)">+ Solicitud</button>
        </div>
    </div>
    <ng-container>
        <div class="col-8 contenido-home" *ngIf="vista==1">
            <app-petitions-list></app-petitions-list>
        </div>
        <div class="col-8 contenido-home" *ngIf="vista==2">
            <app-user-profile></app-user-profile>
        </div>
        <div class="col-8 contenido-home" *ngIf="vista==3">
            <div class="row" style="margin-bottom: 25px;">
                <div class="col-12" style="justify-content: center;">
                    <div class="form-group col-12 col-lg-6">
                        <label for="blood_type">Infórmate:</label>
                        <select [(ngModel)]="info_type" aria-placeholder="Tipo de sangre" class="form-select">
                        <option [ngValue]="0" selected>--- Seleciona ---</option>
                        <option [ngValue]="1">Preguntas frecuentes</option>
                        <option [ngValue]="2">Entidades</option>
                        <option [ngValue]="3">Deberes y derechos</option>
                        </select>
                      </div>
                </div>
            </div>
            <div *ngIf="info_type == 1" class="container-part">
                <p style="font-size: 2vw; font-weight: bold; color: #003FB9;">Preguntas frecuentes</p>
                <div class="row">
                    <div class="col-3">
                        <div class="list-group">
                            <a (click)="changeFAQ(1)" class="list-group-item list-group-item-action" style="cursor: pointer;"><b>Sobre la Sangre</b></a>
                            <a (click)="changeFAQ(2)" class="list-group-item list-group-item-action" style="cursor: pointer;"><b>Proceso de donación</b></a>
                            <a (click)="changeFAQ(3)" class="list-group-item list-group-item-action" style="cursor: pointer;"><b>Voluntariado</b></a>
                          </div>
                    </div>
                    <div class="col-9">
                        <div class="accordion accordion-flush" id="accordionFlushSangre" *ngIf="faq==1">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s1" aria-expanded="false" aria-controls="flush-s1">
                                    <span><b>¿Puedo donar sangre tras haber tenido Covid?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s1" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">Los grupos sanguíneos más comunes en la población son el A positivo y el O positivo, con un 36% aproximadamente cada uno. Sin embargo, el grupo más polivalente (universal) es el O negativo, dado que puede utilizarse para todos los grupos sanguíneos en una situación de urgencia; lo compone entre el 5 y el 7% de la población.</div>
                                </div>
                            </div>
                            
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s2" aria-expanded="false" aria-controls="flush-s2">
                                    <span><b>¿Influye el tipo de raza en la donación de sangre?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s2" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">No. La donación sólo depende del estado de salud de la persona que va a donar.
        
                                    </div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s3" aria-expanded="false" aria-controls="flush-s3">
                                    <span><b>¿Por qué es importante donar sangre?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s3" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">Donar sangre es crucial porque salva vidas. Se utiliza en transfusiones para tratar a pacientes con enfermedades graves, accidentes, cirugías y condiciones médicas crónicas.</div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s4" aria-expanded="false" aria-controls="flush-s4">
                                    <span><b>¿Cuántos tipos de sangre existen?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s4" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">Hay cuatro tipos de sangre principales: A, B, AB y O. Cada uno de estos puede ser Rh positivo o Rh negativo, lo que da lugar a ocho tipos de sangre diferentes.</div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s5" aria-expanded="false" aria-controls="flush-s5">
                                    <span><b>¿Cuál es el tipo de sangre más común?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s5" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">El tipo de sangre más común en la población general es el O+ (O positivo), seguido por el A+ (A positivo).</div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s11" aria-expanded="false" aria-controls="flush-s11">
                                        <span><b>¿Cuál es la compatibilidad de los grupos sanguineos?</b></span>
                                        </button>
                                    </h2>
                                    <div id="flush-s11" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                        <div class="accordion-body">
                                            <table style="border-collapse:collapse;border:none;">
                                                <tbody>
                                                    <tr>
                                                        <td style="width: 39.5pt;border: none;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:justify;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 44.5pt;border-top: none;border-bottom: none;border-left: none;border-image: initial;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:justify;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td colspan="8" style="width:343.2pt;border:solid black 1.0pt;border-left:none;padding:0in 5.4pt 0in 5.4pt;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">Receptor</span></strong></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 39.5pt;border-top: none;border-right: none;border-left: none;border-image: initial;border-bottom: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:justify;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 44.5pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:justify;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.75pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">A+</span></strong></p>
                                                        </td>
                                                        <td style="width: 42.1pt;border-top: 1pt solid black;border-right: 1pt solid black;border-bottom: 1pt solid black;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">A-</span></strong></p>
                                                        </td>
                                                        <td style="width: 42.7pt;border-top: 1pt solid black;border-right: 1pt solid black;border-bottom: 1pt solid black;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">B+</span></strong></p>
                                                        </td>
                                                        <td style="width: 42.05pt;border-top: 1pt solid black;border-right: 1pt solid black;border-bottom: 1pt solid black;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">B-</span></strong></p>
                                                        </td>
                                                        <td style="width: 44.45pt;border-top: 1pt solid black;border-right: 1pt solid black;border-bottom: 1pt solid black;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">AB+</span></strong></p>
                                                        </td>
                                                        <td style="width: 43.8pt;border-top: 1pt solid black;border-right: 1pt solid black;border-bottom: 1pt solid black;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">AB-</span></strong></p>
                                                        </td>
                                                        <td style="width: 43pt;border-top: 1pt solid black;border-right: 1pt solid black;border-bottom: 1pt solid black;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">O+</span></strong></p>
                                                        </td>
                                                        <td style="width: 42.35pt;border-top: 1pt solid black;border-right: 1pt solid black;border-bottom: 1pt solid black;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">O-</span></strong></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td rowspan="8" style="width:39.5pt;border:solid black 1.0pt;border-top:none;padding:0in 5.4pt 0in 5.4pt;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">Donante</span></strong></p>
                                                        </td>
                                                        <td style="width: 44.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:left;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">A+</span></strong></p>
                                                        </td>
                                                        <td style="width: 42.75pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 42.1pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.7pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.05pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 44.45pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 43.8pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 43pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.35pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 44.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:left;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">A-</span></strong></p>
                                                        </td>
                                                        <td style="width: 42.75pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 42.1pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 42.7pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.05pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 44.45pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 43.8pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 43pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.35pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 44.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:left;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">B+</span></strong></p>
                                                        </td>
                                                        <td style="width: 42.75pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.1pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.7pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 42.05pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 44.45pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 43.8pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 43pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.35pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 44.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:left;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">B-</span></strong></p>
                                                        </td>
                                                        <td style="width: 42.75pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.1pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.7pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 42.05pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 44.45pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 43.8pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 43pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.35pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 44.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:left;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">AB+</span></strong></p>
                                                        </td>
                                                        <td style="width: 42.75pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.1pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.7pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.05pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 44.45pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 43.8pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 43pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.35pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 44.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:left;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">AB-</span></strong></p>
                                                        </td>
                                                        <td style="width: 42.75pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.1pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.7pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.05pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 44.45pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 43.8pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 43pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.35pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 44.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:left;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">O+</span></strong></p>
                                                        </td>
                                                        <td style="width: 42.75pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 42.1pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 42.7pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 42.05pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 44.45pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 43.8pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                        <td style="width: 43pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 42.35pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">&nbsp;</span></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 44.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:left;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><strong><span style="font-size:15px;line-height:150%;">O-</span></strong></p>
                                                        </td>
                                                        <td style="width: 42.75pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 42.1pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 42.7pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 42.05pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 44.45pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 43.8pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 43pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                        <td style="width: 42.35pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0in 5.4pt;vertical-align: top;">
                                                            <p style='margin:0in;text-align:center;line-height:150%;font-size:16px;font-family:"Arial",sans-serif;'><span style="font-size:15px;line-height:150%;">X</span></p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s6" aria-expanded="false" aria-controls="flush-s6">
                                    <span><b>¿Cómo se utiliza mi sangre después de donar?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s6" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">Después de la donación, la sangre se somete a pruebas para asegurar su seguridad y se procesa en componentes sanguíneos (glóbulos rojos, plasma, plaquetas) que se utilizan según las necesidades médicas de los pacientes.</div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s7" aria-expanded="false" aria-controls="flush-s7">
                                    <span><b>¿Cuál es la importancia de la compatibilidad de la sangre en las transfusiones?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s7" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body"> La compatibilidad de la sangre es vital para asegurar que la transfusión sea segura y efectiva. Si los tipos de sangre no son compatibles, pueden producirse reacciones adversas.</div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s8" aria-expanded="false" aria-controls="flush-s8">
                                    <span><b>¿Cuánto tiempo lleva donar sangre?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s8" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">El proceso de donación de sangre suele tardar aproximadamente de 30 a 45 minutos, incluyendo el registro, la revisión médica y la propia extracción de sangre.</div>
                                </div>
                                </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s9" aria-expanded="false" aria-controls="flush-s9">
                                    <span><b>¿Se puede donar sangre si se tiene tatuajes o piercings?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s9" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">Sí, en la mayoría de los casos. Si los tatuajes o piercings se hicieron en un estudio acreditado y cumplen con las normas de seguridad, por lo general no hay restricciones para donar sangre.</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s10" aria-expanded="false" aria-controls="flush-s10">
                                    <span><b>¿Cuántas veces al año puedo donar sangre?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s10" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">En general, se permite a los hombres donar sangre cada tres meses y a las mujeres cada cuatro meses. Esto asegura que el cuerpo tenga suficiente tiempo para recuperarse.</div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion accordion-flush" id="accordionFlushSangre" *ngIf="faq==2">
                            <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s1" aria-expanded="false" aria-controls="flush-s1">
                                <span><b>¿Cuáles son los requisitos para donar sangre en Guatemala según el Ministerio de Salud?</b></span>
                                </button>
                            </h2>
                            <div id="flush-s1" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                <div class="accordion-body">
                                    <ul>
                                        <li>Ser mayor de 18 años.</li>
                                        <li>Tener un peso mínimo de 110 libras.</li>
                                        <li>No haberse realizado un tatuaje o perforación corporal con un tiempo mínimo de un año.</li>
                                        <li>Presentar documento de identificación.</li>
                                        <li>Responder a una prueba previo a la extracción, el cual consta de preguntas acerca de enfermedades padecidas, historial médico, hábitos sexuales, higiene, etc. </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s2" aria-expanded="false" aria-controls="flush-s2">
                                    <span><b>¿Cuáles son los tipos de donación de sangre que se pueden realizar?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s2" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body"> En Guatemala, se pueden realizar donaciones de sangre total, donación de plaquetas y donación de plasma.</div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s3" aria-expanded="false" aria-controls="flush-s3">
                                    <span><b> ¿Cuánto tiempo lleva el proceso completo de donación de sangre?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s3" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">El proceso completo, incluyendo el registro, la revisión médica y la extracción de sangre, generalmente lleva alrededor de 45 minutos a 1 hora.</div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s4" aria-expanded="false" aria-controls="flush-s4">
                                    <span><b>¿Es necesario ayunar antes de donar sangre?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s4" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body"> No, no es necesario ayunar antes de donar sangre en Guatemala. Se recomienda haber ingerido una comida ligera antes de la donación.</div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s5" aria-expanded="false" aria-controls="flush-s5">
                                    <span><b> ¿Cuáles son los pasos principales del proceso de donación de sangre?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s5" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">
                                        El procedimiento de donación de sangre generalmente se compone de los siguientes pasos:
                                        <ul>
                                            <li>Registro: Lo primero que se debe hacer es registrarse como donante de sangre. Esto implica proporcionar información básica sobre la salud, historial médico y antecedentes de viajes recientes, así como cualquier medicamento que el voluntario pueda estar tomando.</li>
                                            <li>Evaluación médica: Antes de que se permita donar sangre, se hará una evaluación médica para asegurarse de que el voluntario está en buen estado de salud. Esto incluye una revisión del historial médico, una medición de la presión arterial, un análisis de los niveles de hierro y una prueba de hemoglobina.</li>
                                            <li>Donación de sangre: Una vez que se ha evaluado y le ha determinado elegible para donar, el voluntario procederá a la donación de sangre. Por lo general el proceso de donación de sangre dura 10 minutos, y se extraen aproximadamente 450 ml de sangre.</li>
                                            <li>Descanso y recuperación: Después de donar sangre, es importante tomar unos minutos para descansar y recuperarse. Se te proporcionará algún tipo de refrigerio o bebida para ayudarte a reponer tu energía.</li>
                                            <li>Control de calidad: Una vez que se ha extraído la sangre, se somete a pruebas para asegurarse de que esté libre de enfermedades y segura para su uso en transfusiones.</li>
                                            <li>Uso de la sangre: Una vez que se ha verificado que la sangre es segura para su uso, se almacenará y se utilizará según sea necesario en transfusiones para personas que la necesiten.</li>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s6" aria-expanded="false" aria-controls="flush-s6">
                                    <span><b>¿Se requiere algún tipo de identificación para donar sangre?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s6" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">Sí, es necesario presentar un documento de identificación válido, como el Documento Personal de Identificación (DPI) o el pasaporte guatemalteco.</div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s7" aria-expanded="false" aria-controls="flush-s7">
                                    <span><b> ¿Se realiza alguna prueba de enfermedades antes de aceptar una donación de sangre?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s7" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">Sí, se realizan pruebas de enfermedades transmisibles, como VIH, hepatitis B y C, sífilis y enfermedad de Chagas, para garantizar la seguridad de la sangre donada.</div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s8" aria-expanded="false" aria-controls="flush-s8">
                                    <span><b>¿Cuánto tiempo debe transcurrir entre dos donaciones de sangre?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s8" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body"> Según las regulaciones del Ministerio de Salud de Guatemala, se recomienda que haya un intervalo mínimo de 3 meses (hombres) y 4 meses (mujeres) entre dos donaciones de sangre.</div>
                                </div>
                                </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s9" aria-expanded="false" aria-controls="flush-s9">
                                    <span><b>¿Se puede donar sangre si se ha recibido una vacuna recientemente?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s9" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">En general, se puede donar sangre después de haber recibido una vacuna siempre y cuando no haya presentado ninguna reacción adversa significativa.</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s10" aria-expanded="false" aria-controls="flush-s10">
                                    <span><b>¿Se proporciona algún tipo de compensación o incentivo por la donación de sangre?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s10" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">No, en Guatemala está prohibido ofrecer compensación económica o incentivos para la donación de sangre. La donación de sangre debe ser voluntaria y altruista.</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s11" aria-expanded="false" aria-controls="flush-s11">
                                    <span><b>¿Que tipos de donación existen?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s11" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">
                                        <ul>
                                            <li>Donación altruista: es aquella en la que una persona decide donar sangre sin recibir ningún tipo de compensación económica u otro beneficio personal a cambio. El motivo principal de la donación altruista es ayudar a los demás y contribuir a salvar vidas.</li>
                                            <li>Donación remunerada: en algunos países o regiones, se permite la donación de sangre remunerada, en la que una persona recibe algún tipo de compensación económica o beneficio en especie a cambio de donar sangre. Sin embargo, es importante destacar que esta práctica no está permitida en Guatemala y está penada por la ley debido a preocupaciones éticas y de seguridad.</li>
                                            <li>Donación por reposición: esta se produce cuando una persona dona sangre para reemplazar una cantidad específica de sangre utilizada por un familiar o amigo. La donación por reposición es común en algunos países en los que el suministro de sangre es limitado, pero se prefiere que se promueva la donación voluntaria y altruista en su lugar.</li>
                                          </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion accordion-flush" id="accordionFlushSangre" *ngIf="faq==3">
                            <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s1" aria-expanded="false" aria-controls="flush-s1">
                                <span><b>¿Por qué es importante el voluntariado en la donación altruista de sangre?</b></span>
                                </button>
                            </h2>
                            <div id="flush-s1" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                <div class="accordion-body">El voluntariado es fundamental en la donación altruista de sangre, ya que ayuda a promover conciencia sobre la importancia de donar sangre y facilita el proceso de donación para garantizar un suministro adecuado y seguro de sangre.</div>
                            </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s2" aria-expanded="false" aria-controls="flush-s2">
                                    <span><b>¿Cuáles son algunas formas en las que puedo contribuir como voluntario en la donación de sangre?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s2" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">Puedes contribuir como voluntario en la donación de sangre participando en campañas de sensibilización, ayudando en la promoción y difusión de eventos de donación, asistiendo en la organización logística de los centros de donación y brindando apoyo emocional a los donantes.</div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s3" aria-expanded="false" aria-controls="flush-s3">
                                    <span><b>¿Qué habilidades o cualidades son valoradas en un voluntario de donación altruista de sangre?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s3" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">Algunas habilidades y cualidades valoradas en un voluntario de donación de sangre incluyen la empatía, la comunicación efectiva, la organización, la capacidad de trabajo en equipo y la disposición para aprender y adaptarse a diferentes situaciones.</div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s4" aria-expanded="false" aria-controls="flush-s4">
                                    <span><b>¿Cómo puede mi contribución como voluntario de donación de sangre marcar la diferencia?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s4" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">Tu contribución como voluntario puede marcar la diferencia al motivar a más personas a donar sangre, ayudar a crear un ambiente acogedor y seguro en los centros de donación, y brindar apoyo y aliento a los donantes durante el proceso.</div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s5" aria-expanded="false" aria-controls="flush-s5">
                                    <span><b>¿Qué beneficios puedo obtener al ser voluntario en la donación altruista de sangre?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s5" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body">Ser voluntario en la donación de sangre te brinda la oportunidad de hacer una diferencia directa en la vida de las personas, adquirir nuevas habilidades y conocimientos, establecer conexiones significativas con otros voluntarios y profesionales de la salud, y sentir una satisfacción personal por tu contribución altruista.</div>
                                </div>
                                </div>
                                <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-s6" aria-expanded="false" aria-controls="flush-s6">
                                    <span><b>¿Cómo puedo comenzar a ser voluntario en la donación de sangre?</b></span>
                                    </button>
                                </h2>
                                <div id="flush-s6" class="accordion-collapse collapse" data-bs-parent="#accordionFlushSangre">
                                    <div class="accordion-body"> Puedes comenzar a ser voluntario en la donación de sangre puedes registrarte en red roja y poder recibir peticiones de sangre y jornadas de donacion de las diferentes entidades registradas en al red.</div>
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
                
            </div>   
            <div *ngIf="info_type == 2" class="container-part">
                <div class="row">
                    <!-- <div class="col-12">
                        <p style="font-size: 2vw; font-weight: bold; color: #003FB9;">Entidades</p>
                        <img src="../../../assets/images/landing/entidades.png" style="height: 45%">
                    </div> -->
                    <div class="col-12" style="height: 70vh;">
                        <app-map [index]="-1"></app-map>
                    </div>
                </div>
                
            </div>  
            <div *ngIf="info_type == 3" class="container-part">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="row">
                            <p style="font-size: 2vw; font-weight: bold; color: #003FB9;">Derechos del voluntario</p>
                            <p style="font-size: 1.2vw; font-weight: 100; color: #555555;">
                                <ul>
                                    <li>Ser tratados con respeto y sin discriminación.</li>
                                    <li>Recibir información clara y comprensible sobre el proceso de donación, sus posibles riesgos y efectos secundarios, y cualquier otro aspecto relevante.</li>
                                    <li>Ser informados de los resultados de las pruebas realizadas en su muestra de sangre y de cualquier resultado adverso.</li>
                                    <li>Tomar una decisión libre y voluntaria sobre la donación de sangre, sin ser presionados ni influenciados por terceros.</li>
                                    <li>Ser tratados con confidencialidad y privacidad, y que se respete su privacidad y su información personal.</li>
                                    <li>Recibir atención médica y tratamiento adecuados si sufren cualquier efecto secundario de la donación.</li>
                                </ul>     
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="row">
                            <p style="font-size: 2vw; font-weight: bold; color: #003FB9;">Deberes del voluntario</p>
                            <p style="font-size: 1.2vw; font-weight: 100; color: #555555;">
                                <ul>
                                    <li>Ser honestos sobre su historial médico y sus antecedentes, para garantizar la seguridad de la sangre donada.</li>
                                    <li>Cumplir con los requisitos y criterios de elegibilidad para la donación de sangre, según lo establecido por las autoridades sanitarias.</li>
                                    <li>Seguir las instrucciones del personal médico antes, durante y después de la donación, incluyendo las medidas de precaución y cuidado posterior.</li>
                                    <li>Informar al personal médico si experimentan algún efecto secundario después de la donación.</li>
                                    <li>No esperar a cambio de su donación de sangre, ya que esta debe ser un acto voluntario y altruista.</li>
                                </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-8 contenido-home" *ngIf="vista==4">
            <app-autoexamen></app-autoexamen>
        </div>
        <div class="col-8 contenido-home" *ngIf="vista==6">
            <app-history></app-history>
        </div>
        <div class="col-8 contenido-home" *ngIf="vista==9">
            <app-user-interest></app-user-interest>
        </div>
        <div class="col-8 contenido-home" *ngIf="vista==5">
            <app-achievements></app-achievements>
        </div>
        <!-- <div class="col-8 contenido-home" *ngIf="vista==7">
            <h1>Contenido principal</h1>
            <p>Configuración</p>
        </div> -->
        <div class="col-8 contenido-home" *ngIf="vista==8">
            <app-petitions-form></app-petitions-form>
        </div>
    </ng-container>
</div>