<!-- <div>
    <img src="../../../assets/images/Logo.png" style="height: 50%">
    <
</div> -->
<div class="row" style="height: 100vh;">
    <div class="col-4" style="padding: 3%; border-right: solid 1px #ebebeb; position: fixed; height: 100%;">
        <div class="row" style="margin-left: 30%;">
            <div class="row">
                <img src="../../../assets/images/Logo.png" style="width: 10vw; margin-bottom: 25px;">
            </div>
            <div class="row" class="hover-icon">
                <p style="cursor: pointer; font-size: 1.2rem;" (click)="CambiarVista(1)"><i class="fa-solid fa-triangle-exclamation"></i><a>Denucias</a></p>
            </div>
            <div class="row" class="hover-icon">
                <p style="cursor: pointer; font-size: 1.2rem;" (click)="CambiarVista(2)"><i class="fa-solid fa-clipboard-list"></i><a>tickets</a></p>
            </div>
            <div class="row" class="hover-icon">
                <p style="cursor: pointer; font-size: 1.2rem;" (click)="CambiarVista(3)"><i class="fa-solid fa-user"></i><a>Nuevas instituciones</a></p>
            </div>
            <div class="row" class="hover-icon">
                <p style="cursor: pointer; font-size: 1.2rem;" (click)="cerrarSesion()"><i class="fa-solid fa-arrow-right-from-bracket"></i><a>Cerrar Sesión</a></p>
            </div>
        </div>
    </div>
    <ng-container>
        <div class="col-8 contenido-home" *ngIf="vista==1">
            <!-- <h1>Contenido principal</h1>
            <p>Denuncias</p> -->
            <app-complaints></app-complaints>
        </div>
        <div class="col-8 contenido-home" *ngIf="vista==2">
            <app-tickets></app-tickets>
        </div>
        <div class="col-8 contenido-home" *ngIf="vista==3">
            <app-new-entities></app-new-entities>
        </div>
    </ng-container>
</div>