<div class="container-fluid">
    <div class="row custom-margin">
        <div class="col-md-5 col-form">
            <div style="height: 3%;"></div>
            <img src="../../../assets/images/Logo.png" (click)="navigateHome()" alt="Logo Red Roja" class="img-fluid" style="height: 15%; cursor: pointer;">
            <form #loginForm="ngForm" (ngSubmit)="onSubmit()" class="login-form">
                <div class="form-group">
                    <label for="username">Usuario</label>
                    <input type="text" class="form-control" name="username" placeholder="Ingresa tu usuario" [(ngModel)]="username" required />
                </div>
                <div class="form-group">
                    <label for="password">Contraseña</label>
                    <input type="password" class="form-control" name="password" placeholder="Ingresa tu contraseña" [(ngModel)]="password" required />
                </div>
                <button type="submit" class="btn btn-primary" [disabled]="loginForm.form.invalid">Iniciar Sesión</button>
            </form>
            <p class="mt-3">
                ¿Olvidaste tu contraseña? <a href="./password-recovery">Recupera aquí</a>
            </p>
            <p class="mt-3">
                ¿Aún no tienes usuario? <a href="./registrar">Solicita acceso</a>
            </p>
            <p class="mt-3">
                ¿Deseas iniciar como institución? <a href="./login/institucion">entrar aquí</a>
            </p>
        </div>
        
        <div class="col-md-5 image-login" style="height: 100%;">
            <!-- <img src="../../../assets/images/login/1.jpg" alt="Donemos esperanza" class="img-fluid" style="height: 100%; width: 100%;"> -->
        </div>
    </div>
</div>