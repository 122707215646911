<div class="register-container">
  <div class="row justify-content-center" style="width: 100%;">
    <div class="col-10 col-md-9 form-filled">
      <div style="width: 100%;">
          <div class="row">
              <div class="col-12" style="display: flex; justify-content: center;">
                  <p style="font-size: 2.5vw; font-weight: bold; color: #003FB9;">Creación de solicitud</p>
              </div>
          </div>       
      </div>
      
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="form-group col-12">
              <input type="text" formControlName="titulo" placeholder="*Titulo" class="form-control" required>
            </div>
        </div>
        <div class="row">
          <div class="form-group col-12">
              <textarea type="text" formControlName="descripcion" placeholder="*Descripcion" class="form-control" required></textarea>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12">
              <input type="text" formControlName="telefono" placeholder="*Teléfono" class="form-control" required>
            </div>
        </div>
        <div class="row">
          <!-- <div class="form-group col-12">
              <label for="tipo_sangre">*Tipo de sangre</label>
              <select formControlName="tipo_sangre" aria-placeholder="Tipo de sangre" class="form-select">
              <option *ngFor="let ts of tipos_sangre" [ngValue]="ts.id">{{ts.name}}</option>
              </select>
          </div> -->
          <div class="form-group col-12">
            <label for="tipo_sangre">*Tipo(s) de sangre</label>
            <select formControlName="tipo_sangre" aria-placeholder="Tipo de sangre" class="form-select" (change)="push_blood_type();" onblur="this.selectedIndex = -1;">
              <option *ngFor="let ts of tipos_sangre" [ngValue]="ts">{{ts.name}}</option>
            </select>
            <mat-chip-set #chipSet aria-label="blood selection" style="margin-top: 10px;">
              <mat-chip-row *ngFor="let bt of blood_types_selected" (removed)="remove(bt)" style="background: #DD0426;">
                {{bt.name}}
                <button matChipRemove [attr.aria-label]="'remove ' + bt">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            </mat-chip-set>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12">
              <label for="entity">*Institución donde acudir</label>
              <select formControlName="entity" class="form-select" (change)="SetInfo()">
              <option value="Otra">Otra</option>
              <option *ngFor="let i of instituciones" [ngValue]="i.name">{{i.name}}</option>
              </select>
          </div>
        </div>
        <div class="row" *ngIf="registerForm.controls['entity'].value == 'Otra'">
          <div class="form-group col-12">
              <input type="text" formControlName="otraI" placeholder="*Nombre de la institucion" class="form-control">
            </div>
        </div>
        <div class="row">
          <div class="form-group col-12" *ngIf="registerForm.controls['entity'].value == 'Otra'">
              <label for="departament">*Departamento</label>
              <select formControlName="departament" class="form-select">
              <option *ngFor="let depto of departaments" [ngValue]="depto.id">{{depto.name}}</option>
              </select>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12" style="min-height: 500px;">
              <label>Puedes agregar la ubicación del lugar, para que la publicación tenga mayor información</label>
              <app-map (locationEvent)="getLocation($event)" [locationSended]="locationToSend"></app-map>
          </div>
        </div>
        <div class="form-group" style="display: flex; justify-content: center;">
          <span style="font-size: 0.7rem;">*Campos obligatorios</span>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary" style="margin-bottom: 20px;     position: relative;
          bottom: 0px;
          z-index: 400;">Crear</button>
        </div>
      </form>
    </div>
  </div>
</div>