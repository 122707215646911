import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.css']
})
export class UserActivationComponent {

  is_token_valid: boolean;

  constructor(private service:UsersService, private route: ActivatedRoute){
    this.is_token_valid = false;
  }

  ngOnInit(){
    console.log("soy un componente");
    this.service.new_user_activation(this.route.snapshot.paramMap.get('token')).subscribe({
      next: (response:any) => {
        console.log(response);
        this.is_token_valid = true;
      },
      error: (err:any) => {
        console.error("Error getting token, line 25", err);
      }
    });
  }
}
