import { Component, Output, EventEmitter, Input, SimpleChanges  } from '@angular/core';
import { Router } from '@angular/router';
import * as L from 'leaflet';
import { UsersService } from 'src/app/services/users.service';

interface Location {
  name: string;
  lat: number;
  lng: number;
}


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent {

  map:any;
  iconPer:any;
  selectMarker:any;
  location:any;
  circle:any;
  locations: Location[] = [];
  
  @Output() locationEvent = new EventEmitter<string>();
  @Input() locationSended = "";
  @Input() locationSended_auto = "";
  @Input() index = 0;
  @Input() distance = 0;

  addNewItem(value: string) {
    this.locationEvent.emit(value);
  }

  constructor(private service:UsersService, private router:Router){
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    if(changes['locationSended'] && changes['locationSended'].currentValue != changes['locationSended'].previousValue){
      if(changes['locationSended'].currentValue){
        if(changes['locationSended'].currentValue == -1){
          this.map.removeLayer(this.location);
        }
        else{
          let latlng = changes['locationSended'].currentValue.split(','); 
          this.setupMap();
          
          if("map"+this.index == "map-2"){
            this.addMarker_2(latlng[0],latlng[1],this.distance);
          }
          else{
            this.addMarker(latlng[0],latlng[1]);
          }
        }
      }
    }

    if(changes['locationSended_auto'] && changes['locationSended_auto'].currentValue != changes['locationSended_auto'].previousValue){
      if(changes['locationSended_auto'].currentValue){
        if(changes['locationSended_auto'].currentValue == -1){
          this.map.removeLayer(this.location);     
        }
        else{
          let latlng = changes['locationSended_auto'].currentValue.split(','); 
          this.setupMap();
          this.addMarker(latlng[0],latlng[1]);
        }
      }
    }

    if(changes['distance'] && changes['distance'].currentValue != changes['distance'].previousValue){
      if(changes['distance'].currentValue || changes['distance'].currentValue == 0){
        if(changes['distance'].currentValue <= 0){
          console.log("distancia es -1")
          this.map.removeLayer(this.location);
          if("map"+this.index == "map-2"){
            this.map.removeLayer(this.circle);
          }
        }
        else{
          if("map"+this.index == "map-2" && this.location){
            console.log("location is:",this.location.getLatLng())
            this.addMarker_2(this.location.getLatLng().lat,this.location.getLatLng().lng,this.distance);
          }
        }
      }
      else if(changes['distance'].currentValue == undefined){
        if("map"+this.index == "map-2" && this.circle){
          this.map.removeLayer(this.circle);
        }
        if(this.location){
          this.map.removeLayer(this.location);
        }
      }
    }
 }

  ngAfterViewInit(): void {
    this.setupMap();
  }

  setupMap(): void {

    this.iconPer = L.icon({
      iconUrl: '../../../assets/images/map/marker-icon-2x.png',
      shadowUrl: '../../../assets/images/map/marker-shadow.png',
  
      iconSize:     [19, 42], // size of the icon
      shadowSize:   [25, 32], // size of the shadow
      iconAnchor:   [11, 47], // point of the icon which will correspond to marker's location
      shadowAnchor: [2, 31],  // the same for the shadow
      popupAnchor:  [-1, -36] // point from which the popup should open relative to the iconAnchor
    });

    this.selectMarker = L.icon({
      iconUrl: '../../../assets/images/map/red-pushpin.png',
      shadowUrl: '../../../assets/images/map/marker-shadow.png',
  
      iconSize:     [19, 42], // size of the icon
      shadowSize:   [25, 32], // size of the shadow
      iconAnchor:   [11, 47], // point of the icon which will correspond to marker's location
      shadowAnchor: [2, 31],  // the same for the shadow
      popupAnchor:  [-1, -36] // point from which the popup should open relative to the iconAnchor
    });

    let mapid = "map"+this.index;
    console.log("SETTING MAP: ",mapid);

    if(this.map){
      console.log("Encontro mapa y lo va a eliminar");
      this.map.off();
      this.map.remove();
    }
    console.log("Seteando vista principal");
    this.map = L.map(mapid).setView([14.890057910140378, -90.44985671861984], 7);
    console.log("paso vista principal");

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: 
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(this.map);

    if(mapid == "map0"){
      this.map.on('click', (e:any) => {
        this.addMarker(e.latlng.lat, e.latlng.lng);
      });
    }

    if(mapid == "map-1"){
      this.loadPlaces();
    }

    if(mapid == "map-2"){
      setInterval(() => {
        this.map.invalidateSize();
     }, 200);
      console.log("Es map -2");
      if(!this.distance){
        this.distance = 0;
      }
      this.map.on('click', (e:any) => {
        console.log("hice click", e);
        this.addMarker_2(e.latlng.lat, e.latlng.lng, this.distance);
      });
    }
  }

  loadPlaces(){
    this.service.get_places().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        this.locations = response.data;
        this.locations.forEach(element => {
          let marker = L.marker([element.lat, element.lng], {icon: this.iconPer}).addTo(this.map);
          marker.bindPopup(element.name).openPopup();
        });
      },
      error: (err:any)=>{
        console.error("Error getting departaments, line 43: ",err);
        this.service.openDialog("Error","Error al obtener las ubicaciones, intente mas tarde","../../../assets/images/error.png")
      }
    });
  }

  addMarker(lat:any,lng:any){
   if(this.location){
    this.map.removeLayer(this.location);
   }
    this.location = L.marker([lat, lng], {icon: this.selectMarker}).addTo(this.map);
    this.location.bindPopup(lat+","+lng);
    this.locationEvent.emit(lat+","+lng);
  }

  addMarker_2(lat:any,lng:any,distance:any){
    console.log("el marcadot tiene km de: ",this.distance, distance);
    console.log("location, circle, lat, lng", this.location, this.circle, lat,lng)
    
    let rad = 0;
    if(this.distance){
      rad = this.distance * 1000;
    }
    else{
      rad = 0;
    }
    if(this.location){
     this.map.removeLayer(this.location);
    }
    if(this.circle){
      this.map.removeLayer(this.circle);
    }
     this.location = L.marker([lat, lng], {icon: this.selectMarker}).addTo(this.map);
     this.circle = L.circle([lat, lng], {
          color: 'red',
          fillColor: '#f03',
          fillOpacity: 0.5,
          radius: rad
      }).addTo(this.map);

     this.location.bindPopup(lat+","+lng);
     this.locationEvent.emit(lat+","+lng);
   }

}
