import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../components/dialog/dialog.component';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class EntityService {
  url:string;
  httpOptions:any;
  token:any;

  constructor(  
    private router:Router, private http:HttpClient, public dialog: MatDialog
    ) {
    //this.url = "http://localhost:3000/"
    this.url = "https://www.redroja.online/api/"
    this.set_token();
  }

  get_entity_information(){
    return this.http.get(`${this.url}entity/profile`,this.httpOptions);
  }

  get_entity_tickets(){
    return this.http.get(`${this.url}entity/tickets`,this.httpOptions);
  }

  create_ticket(data:any){
    this.set_token();
    return this.http.post(`${this.url}entity/ticket`,data,this.httpOptions);
  }

  delete_ticket(data:any){
    this.set_token();
    return this.http.put(`${this.url}entity/ticket`,data,this.httpOptions);
  }

  delete_petition(data:any){
    this.set_token();
    return this.http.put(`${this.url}entity/petition`,data,this.httpOptions);
  }

  get_requests(){
    return this.http.get(`${this.url}entity/requests/all`,this.httpOptions);
  }

  openDialog(title:any,description:any,imgUrl:any,type=0,data={}) {
    //types:
    //show save autoexam = 1
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title:title,
        description: description,
        imgUrl: imgUrl
      },
    });
  }

  set_token(){
    this.token =localStorage.getItem("token");

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.token? 'Bearer '+this.token : 'Bearer '+''
      })
    }
  }

  redirect_to_login(){
    localStorage.setItem("token","");
    this.router.navigate(['/login']);
  }
}
