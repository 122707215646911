import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent {

  peticiones:any;
  orderAsc:boolean;

  //external donation
  external_title:any;
  external_description:any;
  external_date:any;

  constructor(private user_service:UsersService, private router:Router){
    this.peticiones = [];
    this.orderAsc = true;
  }

  ngOnInit(){
    this.load_history();
  }

  ordenarPorFecha(arr:any, ascendente = false) {
    const sortedArray = arr.slice(); // Copia el arreglo original para evitar modificarlo directamente
  
    sortedArray.sort((a:any, b:any) => {
      //console.log(a.date_created,b.date_created);
      const fechaA = new Date(a.date_event).getTime();
      const fechaB = new Date(b.date_event).getTime();
  
      if (ascendente) {
        return fechaA - fechaB; // Orden ascendente
      } else {
        return fechaB - fechaA; // Orden descendente
      }
    });
  
    console.log(sortedArray);
  
    return sortedArray;
  }
  
    ordenar(tipo:any){
      console.log("Ordenando tipo: ",tipo)
      if(tipo==1){
        this.peticiones = this.ordenarPorFecha(this.peticiones, false)
        this.orderAsc = false;
      }
      else{
        this.peticiones = this.ordenarPorFecha(this.peticiones, true);
        this.orderAsc = true;
      }
    }

    delete_from_history(petition:any){
      console.log("Vamos a eliminar: ",petition);
      this.user_service.delete_from_history(petition).subscribe({
        next: (response:any)=>{
          console.log("response",response);
          this.user_service.openDialog("Eliminada","La petición fue eliminada del historial","../../../assets/images/corazon_rojo.png");
          this.peticiones = this.peticiones.filter((p:any) => p.petition_id != petition.petition_id);
        },
        error: (error:any) => {
          this.user_service.openDialog("Error","Error al eliminar la peticion del historial, intente mas tarde","../../../assets/images/error.png")
          console.error("Error on delete petition, line 84: ",error);
        }
      })
    }

    register_external(){
      console.log("registrar la externa: ", this.external_date,this.external_description, this.external_title);
      let data = {
        title : this.external_title,
        desctiption: this.external_description,
        date_event: this.external_date
      }
      this.user_service.add_into_history_custom(data).subscribe({
        next: (response:any) => {
          console.log(response);
          this.user_service.openDialog("¡Creada!","Se registro la donación en el historial","../../../assets/images/corazon_rojo.png");
          this.clear();
          this.load_history();
        },  
        error: (err:any) => {
          console.error("Error creating new petition history, on line 1", err);
          this.user_service.openDialog("Error","Error al registrar la peticion al historial, intente mas tarde","../../../assets/images/error.png")
        }
      });
    }

    load_history(){
      this.user_service.get_user_history().subscribe({
        next: (response:any) => {
          console.log("Repsuesta de get history",response);
          this.peticiones = response.data;
          this.peticiones = this.peticiones.map((p:any)=>{
            p.date_event = p.date_event.replace('T',' ').split(' ')[0];
            return p;
          });
        },
        error: (error:any) => {
          console.error("Error getting requests, line 28: ",error);
          this.user_service.openDialog("Error","Error al obtener el historial, intente mas tarde","../../../assets/images/error.png")
        }
      });
    }

    clear(){
      this.external_date = undefined;
      this.external_description = undefined;
      this.external_title = undefined;
    }
}