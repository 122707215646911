<div class="register-container">
    <div class="row justify-content-center" style="width: 100%;">
      <div class="col-10 col-md-9 form-filled">
        <div style="width: 100%;">
            <div class="row">
                <div class="col-12" style="display: flex; justify-content: center;">
                    <p style="font-size: 2.5vw; font-weight: bold; color: #003FB9;">Nuevas instituciones</p>
                </div>
            </div>       
        </div>
        <ng-container *ngFor="let entity of entities">
            <div class="container mt-5">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Datos de la institución al registro</h5>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item"><strong>Nombre:</strong>{{entity.name}}</li>
                      <li class="list-group-item"><strong>Dirección:</strong>{{entity.address}}</li>
                      <li class="list-group-item"><strong>Departamento:</strong>{{entity.depto_text}}</li>
                      <li class="list-group-item"><strong>Email:</strong>{{entity.email}}</li>
                      <li class="list-group-item"><strong>Teléfono:</strong>{{entity.phone}}</li>
                    </ul>
                  </div>
                </div>
                <div style="width: 100%; background-color: mintcream;" 
                    [ngClass]="{'mapVisible': entity.showMap, 'mapHidden' : !entity.showMap }">
                    <app-map [locationSended_auto]="entity.showMap" [index]="entity.id"></app-map>
              </div>
              <div class="row" style="display: flex;">
                <div class="col-12 col-md-6" style="align-items: center; display: flex; justify-content: center;">
                    <button class="btn btn-primary" style="cursor: pointer;" (click)="update_new_entity(entity)">Aceptar</button>
                </div>
                <div class="col-12 col-md-6" style="align-items: center; display: flex; justify-content: center;">
                    <button class="btn btn-primary" style="cursor: pointer;" (click)="reject_new_entity(entity)">Denegar</button>
                </div>
                </div>
              </div>
              
        </ng-container> 
        <div class="col-12" style="display: flex; justify-content: center;" *ngIf="entities.length == 0">
            <p style="font-size: 1.5vw; font-weight: bold;">No hay ninguna solicitud abierta</p>
        </div>      
      </div>
    </div>
</div>
