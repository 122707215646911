import { Component } from '@angular/core';
import { EntityService } from 'src/app/services/entity.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.css']
})

export class CreateTicketComponent {

  tickets:any;

  constructor(private entity_service: EntityService){
    this.tickets = [];
  }

  ngOnInit(){
    this.entity_service.get_entity_tickets().subscribe({
      next: (res:any) => {
        console.log(res);
        this.tickets = res.data;
      },
      error: (err:any) => {
        console.error(err);
        this.entity_service.openDialog("Error","Error al cargar sus tickets","../../../assets/images/error.png");
      }
    })
  }

  delete_ticket(ticket:any){
    Swal.fire({
      title: "Continuar",
      text: "¿Desea continuar?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Guardar",
      denyButtonText: `Cancelar`
    }).then((result:any) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        console.log(ticket);
        this.entity_service.delete_ticket({data: ticket.id}).subscribe({
          next: ()=>{
            this.entity_service.openDialog("¡Eliminado!","El ticket fue eliminado correctamente","../../../assets/images/corazon_rojo.png");
            this.tickets = this.tickets.filter((t:any) => t.id != ticket.id);
          },
          error: (err:any) => {
            console.error("Error deleting ticket", err);
            this.entity_service.openDialog("Error","Error al eliminar el ticket","../../../assets/images/error.png");
          }
        });
      } else if (result.isDenied) {
        Swal.fire("Los cambios no fueron guardados", "", "info");
      }
    }); 
  }
}
