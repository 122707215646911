import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-user-interest',
  templateUrl: './user-interest.component.html',
  styleUrls: ['./user-interest.component.css']
})
export class UserInterestComponent {

  peticiones:any;
  orderAsc:boolean;
  locationToSend:any;
  petition_selected:any;
  complaint_detail:string;
  lat:any;
  lng:any;

  constructor(private user_service:UsersService, private router:Router){
    this.peticiones = [];
    this.orderAsc = true;
    this.petition_selected = undefined;
    this.complaint_detail = "";
  }

  ngOnInit(){
    this.user_service.get_user_interest().subscribe({
      next: (response:any) => {
        console.log("Repsuesta de get history",response);
        this.peticiones = response.data;
        this.peticiones = response.data.map((element:any)=>{
          console.log(element);
          element.date_created = element.date_created.split('T')[0];
          if(element.date_start)element.date_start = element.date_start.replace('T',' ').split('.')[0];
          if(element.date_end)element.date_end = element.date_end.replace('T',' ').split('.')[0];
          if(element.blood_type)element.blood_type = element.blood_type.replaceAll(",",", ");
          element.showMap = "";
          return element;
        });
      },
      error: (error:any) => {
        console.error("Error getting requests, line 28: ",error);
        this.user_service.openDialog("Error","Error al obtener el historial, intente mas tarde","../../../assets/images/error.png")
      }
    });
  }

  toggleMap(petition:any){
    if(petition.showMap){
      this.closeIndividualMap(petition);
    }
    else{
      this.showIndividualMap(petition);
    }
  }

  showIndividualMap(peticion:any){
    console.log("RECIBO: ",peticion);
    this.locationToSend = peticion.lat+","+peticion.lng;
    peticion.showMap = peticion.lat+","+peticion.lng;
    console.log("SETEAR: ",this.locationToSend);
  }

  getLocation(location: string) {
    this.lat = location.split(',')[0];
    this.lng = location.split(',')[1];
    console.log("RECIBIDO: ",this.lat,this.lng);
  }

  closeIndividualMap(peticion:any){
    peticion.showMap = false;
  }

  ordenarPorFecha(arr:any, ascendente = false) {
    const sortedArray = arr.slice(); // Copia el arreglo original para evitar modificarlo directamente
  
    sortedArray.sort((a:any, b:any) => {
      //console.log(a.date_created,b.date_created);
      const fechaA = new Date(a.date_event).getTime();
      const fechaB = new Date(b.date_event).getTime();
  
      if (ascendente) {
        return fechaA - fechaB; // Orden ascendente
      } else {
        return fechaB - fechaA; // Orden descendente
      }
    });
  
    console.log(sortedArray);
  
    return sortedArray;
  }
  
    ordenar(tipo:any){
      console.log("Ordenando tipo: ",tipo)
      if(tipo==1){
        this.peticiones = this.ordenarPorFecha(this.peticiones, false)
        this.orderAsc = false;
      }
      else{
        this.peticiones = this.ordenarPorFecha(this.peticiones, true);
        this.orderAsc = true;
      }
    }

    delete_from_interest(petition:any){
      console.log("Vamos a eliminar: ",petition);
      this.user_service.delete_from_interest(petition).subscribe({
        next: (response:any)=>{
          console.log("response",response);
          this.user_service.openDialog("Eliminada","La petición fue eliminada del listado de interés","../../../assets/images/corazon_rojo.png");
          this.peticiones = this.peticiones.filter((p:any) => p.id != petition.id);
        },
        error: (error:any) => {
          this.user_service.openDialog("Error","Error al eliminar la peticion del listado de interés, intente mas tarde","../../../assets/images/error.png")
          console.error("Error on delete petition, line 78: ",error);
        }
      })
    }

    set_complaint(petition:any){
      this.petition_selected = {...petition};
    }
  
    send_complaint(){
        if(this.router.url === '/'){
          this.user_service.openDialog("Ups...","Esta función es para usuarios registrados, registrate para poder acceder a esta y otras funciones","../../../assets/images/corazon_gris.png");
          return;
        }
        console.log("peticion: ",this.petition_selected);
        console.log("motivo:", this.complaint_detail);
        this.user_service.save_complaint({reason: this.complaint_detail, request: this.petition_selected.id}).subscribe({
          next: (response:any) => {
            console.log(response);
            this.peticiones = this.peticiones.map((p:any)=>{
              console.log("p es:",p)
              if(p.id == this.petition_selected.id){
                p.already_reported = 1;
              }
              return p;
            });
            this.user_service.openDialog("¡Enviada!","La denuncia fue enviada, un administrador revisará la petición. Gracias por aportar a la comunidad.","../../../assets/images/corazon_rojo.png");
          },
          error: (err:any) => {
            console.error("Error al guardar la denuncia", err);
            this.user_service.openDialog("Error","Error al guardar la denuncia, intente mas tarde","../../../assets/images/error.png")
          }
        });
      
    }
}
