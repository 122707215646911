import { Component } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent {


  tickets:any;

  constructor(private admin_service: AdminService){
    this.tickets = [];
  }

  ngOnInit(){
    this.admin_service.get_tickets().subscribe({
      next: (res:any) => {
        console.log(res);
        this.tickets = res.data;
      },
      error: (err:any) => {
        console.error(err);
        this.admin_service.openDialog("Error","Error al cargar sus tickets","../../../assets/images/error.png");
      }
    })
  }

  delete_ticket(ticket:any){

    Swal.fire({
      title: "Dengar Ticket",
      text: "Ingrese un comentario",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Guardar",
      denyButtonText: `Cancelar`,
      showLoaderOnConfirm: true,
      preConfirm: async (comment) => {
        if(!comment){
          Swal.showValidationMessage(`
            Ingrese un comentario
          `);
        }
        else{
          return comment;
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if(result.isDismissed){
        return;
      }
      let data = {
        status: 3, 
        comment: result.value, 
        id: ticket.id
      }
  
      this.admin_service.update_ticket(data).subscribe({
        next: (resp:any) => {
          this.admin_service.openDialog("Aceptada","El ticket fue resuelto.","../../../assets/images/corazon_rojo.png");
          this.tickets = this.tickets.filter((t:any)=> t.id != ticket.id);
        },
        error: (err:any) => {
          this.admin_service.openDialog("Error","Error al cerrar el ticket","../../../assets/images/error.png");
        }
      });
    });
  }

  accept_ticket(ticket:any){

    Swal.fire({
      title: "Notificar de cambios aplicados",
      text: "Ingrese un comentario",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Guardar",
      denyButtonText: `Cancelar`,
      showLoaderOnConfirm: true,
      preConfirm: async (comment) => {
        if(!comment){
          Swal.showValidationMessage(`
            Ingrese un comentario
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if(result.isDismissed){
        return;
      }
      console.log(result);
      let data = {
        status: 2, 
        comment: result.value, 
        id: ticket.id
      }
      this.admin_service.update_ticket(data).subscribe({
        next: (resp:any) => {
          this.admin_service.openDialog("Aceptada","El ticket fue resuelto.","../../../assets/images/corazon_rojo.png");
          this.tickets = this.tickets.filter((t:any)=> t.id != ticket.id);
        },
        error: (err:any) => {
          this.admin_service.openDialog("Error","Error al cerrar el ticket","../../../assets/images/error.png");
        }
      });
    });
  }
}

