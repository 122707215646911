import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.css']
})
export class LoginAdminComponent {
  username:string;
  password:string;

  constructor(private userService:UsersService,private router: Router){
    this.username = '';
    this.password = '';
  }

  onSubmit(){
    console.log(this.username,this.password, "Entre aca");
    this.userService.login_admin({username:this.username, password: this.password}).subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        localStorage.setItem("token",response.data.token);
        this.router.navigate(['/denuncias']);
      },
      error: (err:any)=>{
        console.error(err);
        this.userService.openDialog("Error",err.error.error,"../../../assets/images/error.png");
      }
    });
  }

  navigateHome(){
    this.router.navigate(['/'])
  }
}
