<p style="font-size: 2.5vw; font-weight: bold; color: #003FB9; align-self: center;">Logros</p>
<div *ngFor="let logro of logros" class="logro">
    <div class="logro-img">
      <img [src]="logro.img" [class.grayscale]="logro.actual < logro.complete_in" alt="{{logro.name}}">
    </div>
    <div class="logro-details">
      <h3>{{logro.name}}</h3>
      <p>{{logro.description}}</p>
      <div class="progress-bar">
        <div class="progress" [style.width.%]="(logro.actual / logro.complete_in) * 100"></div>
      </div>
      <p>{{logro.actual}}/{{logro.complete_in}}</p>
    </div>
  </div>