import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../components/dialog/dialog.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  url:string;
  httpOptions:any;
  token:any;

  constructor(  
    private router:Router, private http:HttpClient, public dialog: MatDialog
    ) {
    //this.url = "http://localhost:3000/"
	this.url = "https://www.redroja.online/api/"   
 this.set_token();
  }

  get_complaints(){
    return this.http.get(`${this.url}admin/complaints`,this.httpOptions);
  }

  update_complaint(data:any){
    this.set_token();
    return this.http.put(`${this.url}admin/complaint`,data,this.httpOptions);
  }

  get_tickets(){
    return this.http.get(`${this.url}admin/tickets`,this.httpOptions);
  }

  update_ticket(data:any){
    this.set_token();
    return this.http.put(`${this.url}admin/ticket`,data,this.httpOptions);
  }

  get_new_entities(){
    return this.http.get(`${this.url}admin/new-entities`,this.httpOptions);
  }

  update_new_entities(data:any){
    this.set_token();
    return this.http.put(`${this.url}admin/unew-entities`,data,this.httpOptions);
  }

  reject_new_entities(data:any){
    this.set_token();
    return this.http.put(`${this.url}admin/rnew-entities`,data,this.httpOptions);
  }

  openDialog(title:any,description:any,imgUrl:any,type=0,data={}) {
    //types:
    //show save autoexam = 1
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title:title,
        description: description,
        imgUrl: imgUrl
      },
    });
  }

  set_token(){
    this.token =localStorage.getItem("token");

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.token? 'Bearer '+this.token : 'Bearer '+''
      })
    }
  }

  redirect_to_login(){
    localStorage.setItem("token","");
    this.router.navigate(['/login']);
  }
  //eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imp1YW5qb2hyOTdAZ21haWwuY29tIiwiaWF0IjoxNzExNjk4NjU1LCJleHAiOjE3MTE3MDIyNTV9.Mx8A-bCQQf4QI7dVCL7P6rL-VC-CDMNs07mkQ3JMFz4
}
