<div style="display: flex; margin-bottom: 15px;">
    <div class="row" style="justify-content: space-between; width: 100%;">
        <div class="col-5">
            <span *ngIf="orderAsc" (click)="ordenar(1)" style="cursor: pointer;"><i class="fa-solid fa-arrow-up" (click)="ordenar(1)"></i> Mas antiguos primero</span>
            <span *ngIf="!orderAsc" (click)="ordenar(2)" style="cursor: pointer;"><i class="fa-solid fa-arrow-down" (click)="ordenar(2)"></i>  Mas recientes primero</span>  
        </div>
    </div>
</div>

<div style="display: flex; gap: 10px; align-items: center; margin: 20px;">
    <div style="background-color: #F02D3A; width: 1rem; height: 1rem; border-radius: 6px;"></div>
    <span>Peticiones</span>
    <div style="background-color: #9197AE; width: 1rem; height: 1rem; border-radius: 6px;"></div>
    <span>Jornadas de institución</span>
</div>

<ng-container *ngFor="let p of peticiones">
    <div class="petition-card" *ngIf="p.request.type == 1">
        <div style="width: 3%; height: auto; background-color: #F02D3A;"></div>
        <div style="width: 97%; padding: 15px; background-color: snow;">
            <div style="display: flex; justify-content: space-between;">
                <p><b>{{p.request.title}}</b></p>
            </div>
            <p>{{p.request.description}}</p>
            <hr>
            <div style="display: flex; justify-content: space-between;">    
                <div style="display: flex; gap: 35px; justify-content: flex-start; align-items: baseline;">
                    <span><i class="fa-solid fa-droplet"  matTooltip="Tipo de sangre requerido"></i>{{p.request.blood_type}}</span>
                    <span><i class="fa-regular fa-calendar" matTooltip="Fecha creación"></i>{{p.request.date_created}}</span>
                    <span><i class="fa-solid fa-phone"  matTooltip="Contacto"></i>{{p.request.phone}}</span> 
                    <span><i [ngClass]="{'icon-blue': p.request.showMap, 'icon-black': !p.request.showMap}" class="fa-solid fa-location-dot" matTooltip="Ver ubicación" style="cursor: pointer;" (click)="toggleMap(p)"></i>{{p.request.entity}}</span>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 100%; background-color: mintcream;" 
         *ngIf="p.request.type == 1" [ngClass]="{'mapVisible': p.request.showMap, 'mapHidden' : !p.request.showMap }">
        <app-map [locationSended_auto]="p.request.showMap" [index]="p.request.id"></app-map>
    </div>
    
    <div class="petition-card" *ngIf="p.request.type == 2">
        <div style="width: 3%; height: auto; background-color: #9197AE;"></div>
        <div style="width: 97%; padding: 15px; background-color: #F1F1F1;">
            <div style="display: flex; justify-content: space-between;">
                <p><b>{{p.request.title}}</b></p>
                <!-- <i class="fa-solid fa-plus" matTooltip="Registrar" style="cursor: pointer;"></i> -->
            </div>
            <p>{{p.request.description}}</p>
            <span><i class="fa-regular fa-clock" matTooltip="Hora jornada"></i>{{p.request.date_start}}-{{p.request.date_end}}</span>
            <hr>
            <div style="display: flex; justify-content: space-between;">
                <div style="display: flex; gap: 35px; justify-content: flex-start; align-items: baseline;">       
                    <span><i class="fa-regular fa-calendar" matTooltip="Fecha creación"></i>{{p.request.date_created}}</span>
                    <span><i class="fa-solid fa-phone"></i>{{p.request.phone}}</span>
                    <span><i [ngClass]="{'icon-blue': p.request.showMap, 'icon-black': !p.request.showMap}" class="fa-solid fa-location-dot" matTooltip="Ver ubicación" style="cursor: pointer;" (click)="toggleMap(p)"></i>{{p.request.entity}}</span>
                </div>
            </div>
        </div> 
    </div>
    <div style="width: 100%; background-color: mintcream;" 
         *ngIf="p.request.type == 2" [ngClass]="{'mapVisible': p.request.showMap, 'mapHidden' : !p.request.showMap }">
        <app-map [locationSended_auto]="p.request.showMap" [index]="p.request.id"></app-map>
    </div>
    <div style="border: solid 1px #ebebeb; margin-bottom: 1rem; padding: 15px;">
        <h6>Denuncias de la publicación</h6>
        <hr>
        <div *ngFor="let user of p.users" class="row" style="margin-bottom: 10px;">
            <span><strong>Usuario: </strong>{{user.email}}</span>
            <span><strong>Comentario: </strong>{{user.reason}}</span>
        </div>
        <div class="row" style="display: flex;">
            <div class="col" style="justify-content: center; display: flex; gap: 10px; margin: 10px;">
                <button style="color: #FFFFFF; background-color: #F02D3A; border-color: #F02D3A; box-shadow: none; border-width: 0px; min-width: 2rem; max-width: 50%; padding: 10px 15px; border-radius: 100px;" (click)="accept_complaint(p.id)">Aceptar</button>
                <button class="filter-button" (click)="reject_complaint(p.id)">Rechazar</button>
            </div>
        </div>
    </div>
</ng-container>
