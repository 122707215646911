<div class="register-container">
    <div class="row justify-content-center" style="width: 100%;">
      <div class="col-10 col-md-9 form-filled">
        <div style="width: 100%;">
            <div class="row">
                <div class="col-12" style="display: flex; justify-content: center;">
                    <p style="font-size: 2.5vw; font-weight: bold; color: #003FB9;">Perfil</p>
                </div>
            </div>       
        </div>
        
        <form [formGroup]="profile_form" (ngSubmit)="onSubmit()">
          <div class="row" style="gap: 15px 0px;">
            <div class="form-group col-12 col-lg-6">
                <input type="text" formControlName="name" placeholder="*Nombres" class="form-control" required>
            </div>
            <div class="form-group col-12 col-lg-6">
                <input type="text" formControlName="lastname" placeholder="*Apellidos" class="form-control" required>
            </div>
            <div class="form-group col-12 col-lg-6">
                <input type="text" formControlName="email" placeholder="Email" class="form-control" readonly style="cursor: not-allowed;">
            </div>
            
            <div class="form-group col-12 col-lg-6">
                <input type="text" formControlName="phone" placeholder="Teléfono" class="form-control">
            </div>
            <div class="form-group col-12 col-lg-6">
                <!-- <input type="date" formControlName="birthday" placeholder="Fecha de nacimiento" class="form-control"> -->
                <input formControlName="birthday" onfocus="(this.type='date')" onblur="(this.type='text')"  class="form-control" type="text" placeholder="Fecha de nacimiento"/>
            </div>
            <div class="form-group col-12 col-lg-6">
              <input type="number" formControlName="weight" min="0" placeholder="Peso en libras" class="form-control">
            </div>
            <div class="form-group col-12 col-lg-6">
              <label for="blood_type">Tipo de sangre</label>
              <select formControlName="blood_type" aria-placeholder="Tipo de sangre" class="form-select">
              <option *ngFor="let ts of blood_types" [ngValue]="ts.id">{{ts.name}}</option>
              </select>
            </div>
            <div class="form-group col-12 col-lg-6">
                <label for="departament">Departamento</label>
                <select formControlName="departament" class="form-select">
                <option *ngFor="let depto of departaments" [ngValue]="depto.id">{{depto.name}}</option>
                </select>
            </div>
            <div class="form-group col-12 col-lg-6">
              <label for="sex">Género</label>
              <select formControlName="sex" aria-placeholder="Género" class="form-select">
              <option value="1">Masculino</option>
              <option value="2">Femenino</option>
              <option value="3">Prefiero no decir</option>
              </select>
            </div>
          </div>
          <div class="row" style="margin: 15px 0px 15px 0px;">
            <div class="form-group col-12" style="min-height: 500px;">
                <label>Configurar tu ubicación permite buscar peticiones personalizadas</label>
                <app-map (locationEvent)="getLocation($event)" [locationSended]="locationToSend"></app-map>
                
                <div class="row" style="justify-content: center; align-items: center; margin-top: 20px; gap: 20px;">
                  <hr>
                  <div class="col-12"> 
                    <label *ngIf="can_donate"><strong>Estado:</strong>{{' Potencial donante' }}</label> 
                    <label *ngIf="!can_donate" ><strong>Estado:</strong>{{' No puedes donar ahora'}} <i matTooltipPosition="right" matTooltip="Has donado en los ultimos 3 meses o tu último autoexamen fue insatisfactorio" class="fa-regular fa-circle-question"></i></label> 
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <label><strong>Notificaciones</strong></label>
                    </div>
                    <div class="row">
                      <div class="form-group col-4 check-cols">
                        <!-- <input type="checkbox" formControlName="sms_notification" placeholder="SMS" class="form-control" id="sms_notification"> -->
                        <input type="checkbox" id="sms_notification" name="sms_notification" [checked]="notification_sms" 
                          value="notification_sms" class="custom-checkbox" (change)="change_notification(1)">
                        <label for="scales">SMS</label>
                      </div>
                      <div class="form-group col-4 check-cols">
                        <!-- <input type="checkbox" formControlName="email_notification" placeholder="Correo electrónico" class="form-control" id="email_notification"> -->
                        <input type="checkbox" id="email_notification" name="email_notification" [checked]="notification_email" 
                          value="notification_email" class="custom-checkbox" (change)="change_notification(2)">
                        <label for="scales">Correo electrónico</label>
                      </div>
                      <div class="form-group col-4 check-cols">
                        <!-- <input type="checkbox" formControlName="push_notification" placeholder="Push" class="form-control" id="push_notification" readonly> -->
                        <input type="checkbox" id="push_notification" name="push_notification" [checked]="notification_push" 
                          value="notification_push" class="custom-checkbox" disabled style="border: solid 1px gray;">
                        <label for="scales" style="color: gray;">Push (próximamente)</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <span style="font-size: 0.7rem;">*Campos obligatorios</span>
                  <button type="submit" class="btn btn-primary" style="margin-bottom: 20px;">Actualizar</button>
                </div>
            </div>
          </div>   
          
        </form>
      </div>
    </div>
  </div>