

<div class="container-row">
    <div class="col-12 filled-col">
        <form [formGroup]="recoveryForm" (ngSubmit)="onSubmit()">
            <h2>Recuperación de contraseña</h2>
            <ng-container *ngIf="!pressed">
                <p>Ingresa el correo electrónico de tu cuenta:</p>
                <input id="email" formControlName="email" type="email" required />
                <div>
                    <small *ngIf="recoveryForm.controls['email'].invalid && recoveryForm.controls['email'].touched" >Por favor, ingresa un correo electrónico válido.</small>
                    
                </div>
                <div>
                    <button type="submit" [disabled]="recoveryForm.invalid" style="margin-top: 10px;">Recuperar contraseña</button>
                </div>
            
            </ng-container>
            <ng-container *ngIf="pressed">
                <h3>Revisa tu correo para obtener tu contraseña temporal</h3>
                <a href="./">Ir a la página de inicio</a>
            </ng-container>
        </form>
    </div>
</div>