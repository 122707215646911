import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  vista:number;
  info_type:number;
  faq:number;

  constructor(private router:Router){
    this.vista = 1;
    this.info_type = 0;
    this.faq = 0;
  }

  ngOnInit(){
    console.log(this.router.url);
    if(this.router.url == "/institucion/perfil") this.vista = 1;
    if(this.router.url == "/institucion/tickets") this.vista = 2;
    if(this.router.url == "/institucion/peticiones") this.vista = 3;
    
  }

  CambiarVista(vista:number){
    this.vista = vista;
    if (this.vista == 1) this.router.navigate(['/institucion/perfil']);
    if (this.vista == 2) this.router.navigate(['/institucion/tickets']);
    if (this.vista == 3) this.router.navigate(['/institucion/peticiones']);

  }

  cerrarSesion(){
    localStorage.setItem("token",'');
    this.router.navigate(['/salir']);
  }

  changeFAQ(to:number){
    this.faq = to;
  }
}
