<div class="register-container">
    <div class="row justify-content-center" style="width: 100%;">
      <div class="col-10 col-md-9 form-filled">
        <div style="width: 100%;">
            <div class="row">
                <div class="col-12" style="display: flex; justify-content: center;">
                    <p style="font-size: 2.5vw; font-weight: bold; color: #003FB9;">Creación de Solicitud</p>
                </div>
            </div>       
        </div>
        
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="form-group col-12" style="display: flex; justify-content: space-around;">
                    <div style="display: flex; gap: 5px;">
                        <input type="radio" id="petition_type_1" name="petition_type" 
                        value="2" class="custom-checkbox"  formControlName="petition_type">
                        <label for="petition_type_1"><strong>Jornada</strong></label>
                    </div>

                    <div style="display: flex; gap: 5px;">
                        <input type="radio" id="petition_type_2" name="petition_type" 
                        value="1" class="custom-checkbox" formControlName="petition_type">
                        <label for="petition_type_2" ><strong>Solicitud</strong></label>
                    </div>
                  </div>
              </div>
          <div class="row">
            <div class="form-group col-12">
                <input type="text" formControlName="titulo" placeholder="*Titulo" class="form-control" required>
              </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
                <textarea type="text" formControlName="descripcion" placeholder="*Descripcion" class="form-control" required></textarea>
            </div>
          </div>
          <div class="row">
            <!-- <div class="form-group col-12">
                <label for="tipo_sangre">*Tipo de sangre</label>
                <select formControlName="tipo_sangre" aria-placeholder="Tipo de sangre" class="form-select">
                <option *ngFor="let ts of tipos_sangre" [ngValue]="ts.id">{{ts.name}}</option>
                </select>
            </div> -->
            <div class="form-group col-12">
              <label for="tipo_sangre">*Tipo(s) de sangre</label>
              <select formControlName="tipo_sangre" aria-placeholder="Tipo de sangre" class="form-select" (change)="push_blood_type();" onblur="this.selectedIndex = -1;">
                <option [ngValue]="-1">Todas</option>
                <option *ngFor="let ts of tipos_sangre" [ngValue]="ts">{{ts.name}}</option>
              </select>
              <mat-chip-set #chipSet aria-label="blood selection" style="margin-top: 10px;">
                <mat-chip-row *ngFor="let bt of blood_types_selected" (removed)="remove(bt)" style="background: #DD0426;">
                  {{bt.name}}
                  <button matChipRemove [attr.aria-label]="'remove ' + bt">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              </mat-chip-set>
            </div>
          </div>
          <div style="display: flex; flex-direction: column;" *ngIf="registerForm.controls['petition_type'].value == 2">
            <div style="margin-bottom: 20px;">
                <h6>Fecha y hora de inicio</h6>
                <!-- <input id="startDate" onfocus="(this.type='datetime-local')" onblur="(this.type='text')" class="custom-inputs-filter" type="text" placeholder="Desde" formControlName="date_start"/> -->
                <input id="startDate" type='datetime-local' class="custom-inputs-filter" placeholder="Desde" formControlName="date_start"/>
            </div>
            <div style="margin-bottom: 20px;">
                <h6>Fecha y hora de finalización</h6>
                <!-- <input id="endDate" onfocus="(this.type='datetime-local')" onblur="(this.type='text')" class="custom-inputs-filter" type="text" placeholder="Hasta" formControlName="date_end"/> -->
                <input id="endDate" type='datetime-local' class="custom-inputs-filter" placeholder="Hasta" formControlName="date_end"/>
            </div>
        </div>
          <div class="form-group" style="display: flex; justify-content: center;">
            <span style="font-size: 0.7rem;">*Campos obligatorios</span>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary" style="margin-bottom: 20px; position: relative;
            bottom: 0px;
            z-index: 400;">Crear</button>
          </div>
        </form>
      </div>
    </div>
  </div>