import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent {
  recoveryForm: FormGroup;
  pressed:boolean;

  constructor(private fb: FormBuilder, private user_service:UsersService) {
    this.recoveryForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.pressed = false;
  }

  onSubmit() {
    if (this.recoveryForm.valid) {
      const email = this.recoveryForm.controls['email'].value;
      // Lógica para enviar el email de recuperación
      console.log('Correo de recuperación enviado a:', email);
      this.pressed = true;
      this.user_service.get_temporal_password({email}).subscribe({
        next: (resp:any) => {

        },
        error: (err:any) => {
          console.error("error al enviar temporal", err);
        }
      });
    }
  }
}