<div>
    <div mat-dialog-content class="content-dialog">
        <div style="color: #000000; font-size: 1.2rem; display: contents;">
            <img src="{{data.imgUrl}}" style="max-height: 25vh;">
            <p><b>{{data.title}}</b></p>
            <ng-container *ngIf="getDescType()">
                <ul>
                    <li *ngFor="let data of data.description">{{data}}</li>
                </ul>
            </ng-container>
            <ng-container *ngIf="!getDescType()">
                <p>{{data.description}}</p>
            </ng-container>
                
        </div>
    </div>
</div>
