import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { PetitionsFormComponent } from './components/petitions-form/petitions-form.component';
import { PetitionsListComponent } from './components/petitions-list/petitions-list.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { LandingComponent } from './components/landing/landing.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { HttpClientModule } from '@angular/common/http';
import { MessagesModule } from 'primeng/messages';
import { RegisterSuccessComponent } from './components/register/register-success/register-success.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AutoexamenComponent } from './components/autoexamen/autoexamen.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MapComponent } from './components/map/map.component';
import { RegisterEntityComponent } from './components/register-entity/register-entity.component';
import { LoginEntityComponent } from './components/login-entity/login-entity.component';
import { HomeComponent } from './components/home/home.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { FilterDialogComponent } from './components/filter-dialog/filter-dialog.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { HistoryComponent } from './components/history/history.component';
import { UserInterestComponent } from './components/user-interest/user-interest.component';
import { AchievementsComponent } from './components/achievements/achievements.component';
import { LoginAdminComponent } from './components/login-admin/login-admin.component';
import { HomeComponent as HomeComponentAdmin } from './components/admin/home/home.component';
import { ComplaintsComponent } from './components/admin/complaints/complaints.component';
import { HomeComponent as HomeComponentEntity } from './components/entity/home/home.component';
import { ProfileComponent } from './components/entity/profile/profile.component';
import { CreateTicketComponent } from './components/entity/create-ticket/create-ticket.component';
import { PetitionsComponent } from './components/entity/petitions/petitions.component';
import { PetitionFormComponent as EntityPetitionFormComponent } from './components/entity/petition-form/petition-form.component';
import { TicketsComponent } from './components/admin/tickets/tickets.component';
import { UserActivationComponent } from './components/user-activation/user-activation.component';
import { NewEntitiesComponent } from './components/admin/new-entities/new-entities.component';

@NgModule({
  declarations: [
    AppComponent,
    SideBarComponent,
    PetitionsFormComponent,
    PetitionsListComponent,
    LoginComponent,
    RegisterComponent,
    LandingComponent,
    NotfoundComponent,
    PasswordRecoveryComponent,
    RegisterSuccessComponent,
    NavbarComponent,
    AutoexamenComponent,
    DialogComponent,
    MapComponent,
    RegisterEntityComponent,
    LoginEntityComponent,
    HomeComponent,
    HomeComponentAdmin,
    FilterDialogComponent,
    UserProfileComponent,
    HistoryComponent,
    UserInterestComponent,
    AchievementsComponent,
    LoginAdminComponent,
    ComplaintsComponent,
    HomeComponentEntity,
    ProfileComponent,
    CreateTicketComponent,
    PetitionsComponent,
    EntityPetitionFormComponent,
    TicketsComponent,
    UserActivationComponent,
    NewEntitiesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MessagesModule,
    MatDialogModule,
    MatTooltipModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
