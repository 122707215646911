import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../components/dialog/dialog.component';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  url:string;
  httpOptions:any;
  token:any;

  constructor(  
    private router:Router, private http:HttpClient, public dialog: MatDialog
    ) {
    //this.url = "http://localhost:3000/"
    this.url = "https://www.redroja.online/api/"
    this.set_token();
  }

  login(data:any){
    return this.http.post(`${this.url}user/login`,data);
  }

  login_entity(data:any){
    return this.http.post(`${this.url}user/login/entity`,data);
  }

  login_admin(data:any){
    return this.http.post(`${this.url}user/login/admin`,data);
  }

  get_departaments(){
    return this.http.get(`${this.url}other/departament/all`);
  }

  get_blood_types(){
    return this.http.get(`${this.url}other/blood_types/all`);
  }

  get_email(email:string){
    return this.http.get(`${this.url}user/get_email/${email}`)
  }

  get_email_entity(email:string){
    return this.http.get(`${this.url}user/get_email/${email}`)
  }

  new_user(data:any){
    return this.http.post(`${this.url}user/register`,data);
  }

  new_user_activation(data:any){
    return this.http.get(`${this.url}activate/${data}`);
  }

  new_entity(data:any){
    return this.http.post(`${this.url}user/register/entity`,data);
  }
  
  get_places(){
    return this.http.get(`${this.url}other/places/all`);
  }

  get_requests(){
    return this.http.get(`${this.url}other/requests/all`,this.httpOptions);
  }

  get_requests_filtered(data:any){
    return this.http.post(`${this.url}other/requests/filtered`,data,this.httpOptions);
  }

  save_complaint(data:any){
    return this.http.post(`${this.url}other/requests/complaint`,data,this.httpOptions);
  }

  new_request(data:any){
    this.set_token();
    return this.http.post(`${this.url}other/request`,data,this.httpOptions);
  }

  new_request_entity(data:any){
    this.set_token();
    return this.http.post(`${this.url}entity/request`,data,this.httpOptions);
  }

  get_user_information(){
    this.set_token();
    return this.http.get(`${this.url}user/profile`,this.httpOptions);
  }

  update_profile(data:any){
    this.set_token();
    return this.http.put(`${this.url}user/update`,data,this.httpOptions);
  }

  add_into_interest(data:any){
    this.set_token();
    return this.http.post(`${this.url}user/interest`,data,this.httpOptions);
  }

  add_into_history(data:any){
    this.set_token();
    return this.http.put(`${this.url}user/history`,data,this.httpOptions);
  }

  add_into_history_custom(data:any){
    this.set_token();
    return this.http.post(`${this.url}user/history/custom`,data,this.httpOptions);
  }

  get_user_history(){
    this.set_token();
    return this.http.get(`${this.url}user/history`,this.httpOptions);
  }

  
  delete_from_history(data:any){
    return this.http.post(`${this.url}user/history`,data,this.httpOptions);
  }

  delete_from_interest(data:any){
    return this.http.post(`${this.url}user/interest/delete`,data,this.httpOptions);
  }

  save_autoexam(data:any){
    return this.http.post(`${this.url}user/save-autoexam`,data,this.httpOptions);
  }
  
  get_user_interest(){
    this.set_token();
    return this.http.get(`${this.url}user/interest`,this.httpOptions);
  }

  get_user_achievements(){
    this.set_token();
    return this.http.get(`${this.url}user/achievements`,this.httpOptions);
  }

  update_donor_status(){
    this.set_token();
    return this.http.get(`${this.url}user/update-donor-status`,this.httpOptions);
  }

  get_autoexam(){
    this.set_token();
    return this.http.get(`${this.url}user/autoexam`,this.httpOptions);
  }

  get_temporal_password(data:any){
    this.set_token();
    return this.http.put(`${this.url}user/get-temporal-password`,data,this.httpOptions);
  }

  openDialog(title:any,description:any,imgUrl:any,type=0,data={}) {
    //types:
    //show save autoexam = 1
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title:title,
        description: description,
        imgUrl: imgUrl
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('El diálogo se ha cerrado');
      //type:
      // 1 = autoexamen
      // 2 = autoexamen no registrado
      if (type == 1) {
        console.log('viene de autoexamen', result);
        Swal.fire({
          title: "¿Desea guardar el resultado?",
          text: "El resultado afecta al estado de donación, puedes actualizar el autoexamen en cualquier momento.",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Guardar",
          denyButtonText: `Cancelar`
        }).then((result:any) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.save_autoexam({data: data}).subscribe({
                next: (response:any) =>{
                  console.log("Response: ",response);
                  this.openDialog("Guardado","Se guardo el resultado de tu autoexamen","../../../assets/images/corazon_rojo.png");
                },
                error: (err:any) => {
                  console.error("Error saving autoexam on line 93",err);
                  this.openDialog("Error","Error al guardar el autoexamen, intenta mas tarde","../../../assets/images/error.png");
                }
              });
    
          } else if (result.isDenied) {
            Swal.fire("Los cambios no fueron guardados", "", "info");
          }
        });
      }
      else if(type == 2){
        return;
      }
    });
    return undefined;
  }

  set_token(){
    this.token =localStorage.getItem("token");

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.token? 'Bearer '+this.token : 'Bearer '+''
      })
    }
  }

  is_logged_in(): boolean{
    // this.set_token();
    // let response = this.http.get(`${this.url}user/profile`,this.httpOptions);
    // await response.subscribe({
    //   next: (data) =>{
    //     return true;
    //   },
    //   error: (error) =>{
    //     if(error.status == 403 || error.status == 401){
    //       return false;
    //     }
    //     return true;
    //   }
    // });
    let token = localStorage.getItem("token");
    console.log("El token es: ", token)
    if (token){
      return true;
    }
    return false;
  }

  redirect_to_login(){
    localStorage.setItem("token","");
    this.router.navigate(['/login']);
  }
  //eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imp1YW5qb2hyOTdAZ21haWwuY29tIiwiaWF0IjoxNzExNjk4NjU1LCJleHAiOjE3MTE3MDIyNTV9.Mx8A-bCQQf4QI7dVCL7P6rL-VC-CDMNs07mkQ3JMFz4
}
