
<div class="container-row">
    <div class="col-12 filled-col" *ngIf="is_token_valid">
        <h1>Registro Exitoso</h1>
        <p>¡Felicidades! Tu registro ha sido exitoso.</p>
        <a href="./">Ir a la página de inicio</a>
    </div>
    <div class="col-12 filled-col" *ngIf="!is_token_valid">
        <h1>Lo sentimos...</h1>
        <p>El enlance utilizado es inválido o ha caducado, por favor realiza de nuevo el registro.</p>
        <a href="./">Ir a la página de inicio</a>
    </div>
</div>