<div class="register-container">
    <div class="row justify-content-center" style="width: 80%;">
      <div class="col-10 col-md-7 form-filled">
        <div style="width: 100%;">
            <div class="row">
                <div class="col-12" style="display: flex; justify-content: center;">
                    <img src="../../../assets/images/Logo2.png" (click)="navigateHome()" style="width: 25%; cursor: pointer;"> 
                </div>
                <div class="col-12" style="display: flex; justify-content: center;">
                    <h4>Registro</h4>
                </div>
            </div>       
        </div>
        
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="form-group col-12 col-sm-6">
                <label for="nombre">*Nombre</label>
                <input type="text" formControlName="nombre" class="form-control">
              </div>
              <div class="form-group col-12 col-sm-6">
                <label for="apellido">*Apellido</label>
                <input type="text" formControlName="apellido" class="form-control">
              </div>
          </div>
          <div class="form-group">
            <label for="email">*Email</label>
            <input type="email" formControlName="email" class="form-control">
            <span class="text-danger" *ngIf="emailExists">*El correo ingresado ya fue registrado</span>
          </div>
          <div class="row">
            <div class="form-group col-12 col-sm-6">
                <label for="contrasena">*Contraseña</label>
                <input type="password" formControlName="contrasena" class="form-control">
                <span class="text-danger" *ngIf="wrongPassword">*La contraseña debe tener minimo 6 caracteres</span>
            </div>
            <div class="form-group col-12 col-sm-6">
                <label for="repetirContrasena">*Repetir Contraseña</label>
                <input type="password" formControlName="repetirContrasena" class="form-control">
                <span class="text-danger" *ngIf="passwordInequal">*Las contraseñas no coinciden</span>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12 col-sm-6">
                <label for="departament">*Departamento</label>
                <select formControlName="departament" class="form-select">
                <option *ngFor="let depto of departaments" [ngValue]="depto.id">{{depto.name}}</option>
                </select>
            </div>
            <div class="form-group col-12 col-sm-6">
                <label for="telefono">Teléfono</label>
                <input type="tel" formControlName="telefono" class="form-control" minlength="8" maxlength="8">
            </div>
          </div>
          <div class="form-group" style="display: flex; justify-content: center;">
            <span style="font-size: 0.7rem;">*Campos obligatorios</span>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary">Registrarse</button>
          </div>
        </form>
        <p class="mt-3" style="text-align: center;">
            ¿Ya tienes usuario? <a href="./login">Iniciar sesión aquí</a>
        </p>
      </div>
    </div>
  </div>