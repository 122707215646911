import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  departaments: any;

  emailExists:boolean;
  passwordInequal:boolean;
  wrongPassword:boolean;
  messages:Message[];

  constructor(private formBuilder: FormBuilder, private userService:UsersService, private router:Router) { 
    this.departaments = [];
    this.emailExists = false;
    this.passwordInequal = false;
    this.wrongPassword = false;
  }

  ngOnInit(){
    this.registerForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contrasena: ['', [Validators.required, Validators.minLength(6)]],
      repetirContrasena: ['', Validators.required],
      departament: ['', Validators.required],
      telefono: ['',Validators.nullValidator]
    });

    this.userService.get_departaments().subscribe({
      next: (response:any) => {
        console.log("Respuesta: ",response);
        this.departaments = response.data;
      },
      error: (err:any)=>{
        console.error("Error getting departaments, line 43: ",err);
      }
    });

    this.messages = [{ severity: 'success', summary: 'Success', detail: 'Message Content' }]
  }

  onSubmit() {
    this.resetFlags();
    this.validatePassword(); 
    this.validateEmail();
    if(this.emailExists || this.wrongPassword || this.passwordInequal){
      return;
    }
    if (this.registerForm.valid) {
      let data = {
        password: this.registerForm.controls['contrasena'].value,
        first_name: this.registerForm.controls['nombre'].value,
        last_name: this.registerForm.controls['apellido'].value,
        depto: this.registerForm.controls['departament'].value,
        email: this.registerForm.controls['email'].value,
        phone: this.registerForm.controls['telefono'].value
      }
      this.userService.new_user(data).subscribe({
        next: (response:any)=>{
          console.log(response)
          if(response.status){ 
            this.router.navigate(['/bienvenido']);
          }
        },
        error: (err:any)=>{
          console.error("Error on create new user, line 68: ",err);
        }
      });
    } 
    else {
      this.validatePassword();
      this.validateEmail();
      console.log('Formulario inválido');
    }
  }

  validatePassword(){
    console.log(this.registerForm.controls);
    if(this.registerForm.controls['contrasena'].value != this.registerForm.controls['repetirContrasena'].value){
      this.passwordInequal = true;
    }
    if(this.registerForm.controls['contrasena'].value.length < 6){
      this.wrongPassword = true;
    }
  }
  validateEmail(){
    console.log("validate email init")
    if(this.registerForm.controls['email'].value){
      this.userService.get_email(this.registerForm.controls['email'].value).subscribe({
        next: (response:any)=>{
          console.log(response);
        },
        error: (err:any)=>{
          if(err.status == 403){
            this.emailExists = true;
          }
          else{
            console.error("Error gettin email, line 78: ",err);
          }
        }
      });
    }
  }
  resetFlags(){
    this.emailExists = false;
    this.passwordInequal = false;
    this.wrongPassword = false;
  }
  navigateHome(){
    this.router.navigate(['/'])
  }
}
